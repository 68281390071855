import React, { useEffect } from 'react';
import photo1 from "./img/logo.png";
import photo2 from "./img/swirly-arrow.png";
import photo3 from "./img/turn-right-arrow.png";
import equalpay from "./img/equal-pay.png";
import zerofees from "./img/zero-fees.png";
import marketing from "./img/marketing.png";
import commission from "./img/commission.png";
import support from "./img/support.png";
import technology from "./img/technology.png";
import training from "./img/training.png";
import office from "./img/office-space.png";
import homepage from "./img/homepage.jpg";
import { NavLink } from 'react-router-dom';
import Header from "./header";
import Footer from "./footer";


function FAQ() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <body class="">
    <div>
       <main className="page-wrapper">
          <Header />

          <section class="common_page_layout container mb-4 pb-lg-5">
            <h1 class="mx-auto mb-2 pb-2 text-center">HAVE DOUBTS?</h1>
            <h3 className="text-center">Frequent Asked Questions?</h3>
            {/* <form class="form-group mb-4">
              <div class="input-group input-group-lg">
                <input class="form-control" type="text" placeholder="What are you looking for?"/>
              </div>
              <button class="btn btn-primary btn-lg px-sm-4 px-3" type="submit"><i class="fi-search me-sm-2"></i><span class="d-sm-inline d-none">Search</span></button>
            </form> */}
            {/* <div class="d-flex flex-md-row flex-column align-items-md-center">
              <div class="pb-2 mb-2 me-2 flex-shrink-0 fw-bold">Popular sections:</div>
              <div class="text-nowrap overflow-auto pb-2">
                <div><a class="btn btn-xs btn-outline-secondary rounded-pill fw-normal mb-2 me-2" href="#">Notifications</a><a class="btn btn-xs btn-outline-secondary rounded-pill fw-normal mb-2 me-2" href="#">Getting started</a><a class="btn btn-xs btn-outline-secondary rounded-pill fw-normal mb-2 me-2" href="#">Transactions</a><a class="btn btn-xs btn-outline-secondary rounded-pill fw-normal mb-2 me-2" href="#">Account questions</a><a class="btn btn-xs btn-outline-secondary rounded-pill fw-normal mb-2 me-2" href="#">Mortgage</a><a class="btn btn-xs btn-outline-secondary rounded-pill fw-normal mb-2 me-2" href="#">Guides</a></div>
              </div>
            </div> */}
          </section>
          <section class="container mb-md-5 mb-4 pb-lg-5">
            <div class="row">
              {/* <aside class="col-xl-2 col-lg-3">
                <div class="offcanvas-lg offcanvas-start" id="help-sidebar">
                 
                  <div class="offcanvas-body">
                    <ul class="nav nav-tabs navbar-nav flex-column m-0 text-lg-center">
                      <li class="nav-item px-0"><a class="nav-link active px-3" href="#">Renters &amp; Rentals</a></li>
                      <li class="nav-item px-0"><a class="nav-link px-3" href="#">Property Buyers</a></li>
                      <li class="nav-item px-0"><a class="nav-link px-3" href="#">Property Owners</a></li>
                      <li class="nav-item px-0"><a class="nav-link px-3" href="#">Mortgages</a></li>
                      <li class="nav-item px-0"><a class="nav-link px-3" href="#">Mobile&nbsp;App&nbsp;Questions</a></li>
                    </ul>
                  </div>
                </div>
              </aside> */}
              <div class="col-lg-9 offset-xl-1 col-lg-8">
                {/* <h2 class="mb-md-4 mb-3 pb-md-2">What are your fees?</h2> */}
                <div class="pb-md-2">
                  <h3 class="h5">What are your fees?</h3>
                  <p>We have been offering our 100% transaction-based model longer than any other brokerage in Utah.  No more splitting commission with brokers, monthly fees, or other junk fees. On each transaction, In Depth Realty is compensated with a $595 transaction fee.  Nothing more.  We also don’t charge fees to your clients.</p>
                </div>
                <div class="pb-md-2">
                  <h3 class="h5">Do you have a CRM and what does it do?</h3>
                  <p>Yes. Within the CRM you can easily manage your contract, build your website, and lead generate through systems like newsletters, email campaigns, property alerts, landing pages, SMS lead capture, and more.</p>
                </div>
                <div class="pb-md-2">
                  <h3 class="h5">Do you allow Teams?</h3>
                  <p>Yes. Our cap structure is team-friendly, our centralized support and training enable team leaders to be more efficient, and we have a number of tools that they can use.</p>
                </div>
                <div class="pb-md-2">
                  <h3 class="h5">Do you offer support, and do I have someone available to me for help?</h3>
                  <p>Yes, you will have complete access to a broker seven days a week. We play an active role in our agent’s success and encourage our agents to contract us with questions, big or small.  We make it very easy and provide a “Broker on Duty” link on our online calendar to make it simple for you to find whom to contact with questions on the weekends and holidays.  We also have a every document you could possibly need on our website that can easily be found with the search box.</p>
                </div>
                <div class="pb-md-2">
                  <h3 class="h5">What kind of training do you offer?</h3>
                  <p>We have ongoing in-office training year round in addition to pre-recorded training libraries and online videos, Live and on-demand training are all housed on our website, agents can also take advantage of our weekly mastermind group meetings, mentorship program, and one on one training.</p>
                </div>
                <div class="pb-md-2">
                  <h3 class="h5">Do you have a productivity requirement?</h3>
                  <p>We do not have a productivity requirement but we want to make sure that our agents are reaching their goals and full potential as real estate professionals.  The most important thing for us is your success.  If you are struggling, we will discuss option from mentorship, growth teams, traditional teams, and even 1 on 1 coaching. The important thing is if you want to be successful in Real Estate, we will work with you to achieve that.  Because we don’t charge any fees of any kind we want to make sure you’re having closings.  If you’re not making money neither are we.  We are truly in this with you.</p>
                </div>
                <div class="pb-md-2">
                  <h3 class="h5">What are your offices like?</h3>
                  <p>Our offices are located in class A office buildings and are created and furnished to be locations you would be proud to bring your clients to and meet with.  They feature beautiful décor and are consistent with our brand.  You will find conference rooms, day offices, open workspaces, beverages for you and your guests, and fast Wi-Fi.  Your local In Depth Realty office is beautifully designed and available to you at no extra charge.</p>
                </div>
                <div class="pb-md-2">
                  <h3 class="h5">How do I get paid?</h3>
                  <p>We use a third party payroll company to pay via direct deposit.  When your transaction is complete your payment will be processed and sent to our payroll company.  Funds are usually available next day.</p>
                </div>
                <div class="pb-md-2">
                  <h3 class="h5">Can I do rental and property management with In Depth Realty?</h3>
                  <p>At this time In Depth Realty is not supporting any ongoing property management services. Our focus is on sale and purchase transactions only.  We have a few companies that we work with that you can refer you property management to for a fee.</p>
                </div>

                <div class="pb-md-2">
                  <h3 class="h5">How quickly can you get me set up?</h3>
                  <p>Its super simple to join In Depth Realty and the entire setup process usually take less than 24 hours to complete. It can often be complete in just a few hours. The speed of on boarding is determined by how responsive you and your current broker are in completing the required tasks.</p>
                </div>
                <div class="pb-md-2">
                  <h3 class="h5">What areas do you serve?</h3>
                  <p>In Depth Realty Realty currently operates offices and services the entire state of Utah. If you are a productive agent or team leader in a State we currently do not serve contact us, we are looking to open new States and you just might be the first agent/team in your State. Please contact us if you have any questions at all about availability.</p>
                </div>

                <div class="pb-md-2">
                  <h3 class="h5">Do I need to change my Board or MLS?</h3>
                  <p>Absolutely Not! In Depth Realty is a member of multiple REALTOR Associations and MLS’s throughout the States we serve. If for any reason we are not yet a member of your local association then In Depth Realty can join that association.</p>
                </div>
              </div>
            </div>
          </section>
         
          <section className="container-fluid pb-5 p-3 m-0 orange-bg">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <p className="mb-2 pb-2 mx-auto text-dark"><span className="left-horizontal-bar position-relative ps-5">JOIN In Depth Realty</span></p>
              <h2 className="mb-2">Schedule an Appointment​</h2>
              <p className="mb-lg-4 fs-lg text-muted">Come learn about why In Depth Realty is one of the most innovative brokerages in Utah! Please select one of the appointment options below. We’re available whether you’d like to meet in person, online, or chat on the phone</p>
              <h5 className="mb-2 text-center">SCHEDULE NOW!</h5>
            </div>
            <div class="col-md-5 col-lg-6">
              {/* <!-- Calendly inline widget begin --> */}
              <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/chatwithintegrarealty?primary_color=b1b1b1"
        style={{ minWidth: '320px', height: '880px' }}
      />
            </div>
            
            </div>
        </section>
      
          {/* <!-- Footer--> */}
          <Footer />
    </main>
    </div>
    </body>
        
  );
}

export default FAQ;
