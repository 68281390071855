
import React, { useEffect } from 'react';
import photo1 from "./img/logo.png";
import carousel1 from "./img/01.jpg";
import iphone from "./img/iphone.png";
import brokerimage from "./img/broker-image.png";
import indepthmackbook from "./img/Indepth-Macbook.png";
import { NavLink } from 'react-router-dom';
import Header from "./header";
import Footer from "./footer";

function Appointment() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
    return (
      <div>
     
    <main className="page-wrapper">
          <Header />
 
    {/* <section class="container common_page_layout pb-5  mb-md-3">
        <div class="row align-items-center">
          <div class="col-md-12  text-center ">
            <h2 class="mb-md-4">We Care About Your Success</h2>
            <p class="fs-lg p-2 pb-md-3">At In Depth Realty, we care about your success as much as you do. With us you’re not just a number, you’re a member of our family. In Depth Realty offers easy broker access and support. Our support staff and brokers are available to help answer any and all of your questions. We’re also online so feel free to instant message, call, text, or pop-in at anytime!</p>
          </div>
        </div>
      </section> */}
      {/* <!-- How it works--> */}
      <div className="common_page_layout">
            <section class="container mb-5 pb-lg-5 pb-md-5 pb-lg-4">
          <div class="row gy-4">
            <div class="col-md-12">
                  <h2 class="h3 text-center">Schedule an appointment and find
                    out if In Depth Realty is right for you!</h2>
                    <div className="text-center">
                    <a class="btn btn-primary btn-md rounded-pill ms-2 order-md-3" target="_blank" href="https://calendly.com/chatwithintegrarealty">Join Us<i class="fi-like ms-2"></i></a>
                    </div>
            </div>
                <div class="user_profile_information border bg-secondary shadow-sm rounded-3 p-3 mt-5">
                  <a class="d-flex align-items-start text-body text-decoration-none" href="#">
                    <img class="rounded-circle shadow-sm" src="https://movetointegra.com/wp-content/uploads/uo.png" width="150" alt="Kristin Watson"/><br/>
                    <div class="ps-lg-3 ps-md-3 ps-3">
                      <h5 className="mb-0">Shawn Kenney, Principal Broker.</h5>
                        <h6 class="mb-1 mt-2">EXPERIENCE The Difference​:</h6>
                        <h5 class="mb-1 mt-2">Contact us:</h5>
                        <p class="fs-md mb-2">Corporate Office 10808 South River Front Parkway, Suite 3065 South Jordan</p>
                        <p class="fs-md mb-2">Utah <b>84095</b></p>
                        <p class="fs-md">Phone No: <b>801-381-3352</b></p>
                    </div>
                  </a>
                  <p class="fs-md">Shawn Kenney Principal Broker of In Depth Realty was awarded Salt Lake Board of Realtors® 2019 Good Neighbor Award. This award recognizes members for their charitable service. Shawn Kenney who has rescued more than 400 people while serving on the Salt Lake County Sheriff’s Search and Rescue team over the past 7 years. Shawn has also served on the Government Affairs/RPAC Committee as well as the Grievance Committee. Congratulations to our award winners and thank you for your service!</p>
                  </div>
           
          </div>
        </section>

            <section className="container d-flex align-items-center justify-content-center mb-5 pb-lg-5 pb-3 pb-sm-4">
              <div className="col-md-6">
              <h2 class="h3 text-center">Ready to transfer?</h2>
              <h5 class="text-center">Here’s how to get started:</h5>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Step One:</button>
                </h2>
                <div class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" id="collapseOne">
                  <div class="accordion-body">Begin your change with the Division of Real Estate
                    Goto <a href="https://secure.utah.gov/account/log-in.html">https://secure.utah.gov/account/log-in.html</a>
                    Login with the information that you created with the Division (or request your password using the appropriate link
                    Start typing In Depth Realty and select the office you are joining. Our corporate office is In Depth Realty, LLC
                    Change your Affiliation
                    An email will be sent to both brokers to notify everyone of the change. Both brokers will click a link to acknowledge the transfer.</div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Step Two:</button>
                </h2>
                <div class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" id="collapseTwo">
                  <div class="accordion-body">Our office will send you a Docusign envelope with the following documents In this envelope, you will receive and sign the following: Independent Contractor Agreement Primary Board membership Change Form MLS Change Form Direct Deposit Form IRS W-9 Form</div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Step Three:</button>
                </h2>
                <div class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" id="collapseThree">
                  <div class="accordion-body">Once we receive your complete Docusing packet back our office will send your transfer forms to the board and MLS and take care of this step for you.</div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="heading4">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4"> Step Four:</button>
                </h2>
                <div class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample" id="collapse4">
                  <div class="accordion-body">You will receive a separate email inviting you login and create your profile on our platform. This will give you access to all of In Depth Realty’s full suite of tools.</div>
                </div>
              </div>
            </div>
              </div>
            </section>


        
            <section className="container-fluid pb-5 p-3 m-0 orange-bg">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <p className="mb-2 pb-2 mx-auto text-dark"><span className="left-horizontal-bar position-relative ps-5">JOIN In Depth Realty</span></p>
              <h2 className="mb-2">Schedule an Appointment​</h2>
              <p className="mb-lg-4 fs-lg text-muted">Come learn about why In Depth Realty is one of the most innovative brokerages in Utah! Please select one of the appointment options below. We’re available whether you’d like to meet in person, online, or chat on the phone</p>
              <h5 className="mb-2 text-center">SCHEDULE NOW!</h5>
            </div>
            <div class="col-md-5 col-lg-6">
              {/* <!-- Calendly inline widget begin --> */}
              <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/chatwithintegrarealty?primary_color=b1b1b1"
        style={{ minWidth: '320px', height: '880px' }}
      />
            </div>
            
            </div>
        </section>
      </div>
          {/* <!-- Footer--> */}
          <Footer />
          
    </main>
     </div>
  );
}

export default Appointment;