
import React, { useState, useEffect } from 'react';

import photo1 from "./img/logo.png";
import carousel1 from "./img/01.jpg";
import iphone from "./img/iphone.png";
import brokerimage from "./img/broker-image.png";
import indepthmackbook from "./img/Indepth-Macbook.png";
import { NavLink } from 'react-router-dom';
import Header from "./header";
import Footer from "./footer";

function AgentListing() {
  
  const [agents, setAgents] = useState([]);
  useEffect(() => {
  
    fetch('https://api.brokeragentbase.com/contacts?page=1')
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAgents(data);
      })
      .catch((error) => {
        console.error('Error fetching agents:', error);
      });
  }, []);
  
    return (
      <div>
     
    <main className="page-wrapper">
          <Header /> 

      {/* <!-- How it works--> */}
      <div className="common_page_layout">
      <section class="container common_page_layout mb-md-3"><div class="row align-items-center"><div class="col-md-12  text-center "><h2 class="mb-md-4">Agent Listing</h2>
      {/* <p class="fs-lg p-2 pb-md-3">At In Depth Realty, we care about your success as much as you do. With us you’re not just a number, you’re a member of our family. In Depth Realty offers easy broker access and support. Our support staff and brokers are available to help answer any and all of your questions. We’re also online so feel free to instant message, call, text, or pop-in at anytime!</p> */}
      </div>
      </div>
      </section>
 
      <section class="container mb-5 pb-5 pb-lg-4">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>User</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Position</th>
                  
                  </tr>
                </thead>
                <tbody>
                {
                  agents.data && agents.data.length>0?
                    agents.data.map((agent, index) => (

                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td><img  src={agent.image}  width={50}  /></td>
                        <td>{agent.firstName}</td>
                        <td>{agent.lastName}</td>
                        <td>{agent.designations}</td>
                      
                      </tr>
                        ))

                        :""
                  
                  } 
                
            </tbody>
              </table>
          </div>
      </section>
      </div>
          {/* <!-- Footer--> */}
          <Footer />
    </main>
     </div>
  );
}

export default AgentListing;