import React from 'react';
import Star from "./img/five-golden-stars-client.png";

import iphone from "./img/iphone.png";
import { NavLink } from 'react-router-dom';
import photo1 from "./img/logo.png";
import Shawn from "./img/shawn-indepth-realty.png";
import experience from "./img/experience.webp";


import indepthutah from "./img/Indepth-realty-utah.jpg";
import backoffice from "./img/Indepth-realty-screenshot.png";
import Header from "./header";
import Footer from "./footer";


function About() {
  return (
    <div>
      <main>
        <Header />

        <section class="container common_page_layout pb-lg-5 pb-md-5 mb-md-3">
        <div class="row align-items-center">
          <div class="col-md-12 text-center">
            <h2 class="mb-md-4">About Us</h2>
            <p class="fs-lg p-2 pb-md-3">Technology is an area where we definitely stand out. Real Estate technology is our passion and when you join InDepth Realty you’ll experience the feeling of having a technology partner by your side all the time.</p>
          </div>
        </div>
      </section>

        <section class="container mb-5 pb-2 pb-lg-4">
          <div class="row gy-4 align-items-center">
            <div class="col-lg-6">
              <div class="steps steps-vertical">
                <div class="step active">
                <div class="step-progress"><span class="step-number"><i class="fa fa-check" aria-hidden="true"></i></span></div>
                  <div class="step-label ms-4">
                  <h2 class="h3 mb-sm-4">Experience The Difference</h2>
                    <p class="mb-4 pb-3 fs-lg">Technology is an area where we definitely stand out. Real Estate technology is our passion and when you join InDepth Realty you’ll experience the feeling of having a technology partner by your side all the time.

At InDepth Realty we invest heavily in developing our own proprietary tools and technology, our hands on approach allows us innovate and not be limited to the offerings of third party companies. This also allows us to provide the best technology to our agents at no cost to them since we build our own specialized technology.</p>

                  </div>
                </div>

              </div>
            </div>
            <div class="col-md-6 col-12"><img class="d-block mx-auto img-round" src={experience} alt="Illustration" /></div>
          </div>
        </section>

        {/* <!-- How it works--> */}
        <section class="container pt-5 mt-4 pt-5 pb-2 pb-lg-4">
          <div class="row gy-4 align-items-center">
            <div class="col-md-6 order-lg-1 order-md-1 order-sm-2 order-2 col-12"><img class="d-block mx-auto img-round" src={backoffice} alt="Illustration" /></div>
            <div class="col-md-6 order-lg-2 order-md-2 order-md-1 order-1">
            
              <div class="steps steps-vertical">
                <div class="step active">
                <div class="step-progress"><span class="step-number"><i class="fa fa-check" aria-hidden="true"></i></span></div>
                  <div class="step-label ms-4">
                    <h2 class="h3 mb-lg-5 mb-sm-4">Our Agent Back Office</h2>

                    <p class="mb-0"> Our Agent Back Office technology offers everything you need as an agent to be efficient, organized and effective in running and growing your business from anywhere 24/7. Some of the features include: Full paperless transaction management with calendars and statistics, marketing library, training library,(CRM) contact management system, task manager, marketing center, office announcements, office and class calendars & much more.</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>


        <section className="container mb-5 mt-4 pt-5 pb-lg-4">
          <div className="row">
            <div className="col-lg-6 col-md-7">
              <div className="row gy-4">
                <div className="col-md-12">
                  <h2 className="h3 mb-2">THANK YOU! From Our Family to Yours… </h2>
                  <p>Thank you for taking the time out of your busy day to learn more about In Depth Realty. I invite you to explore our website to discover the amazing benefits we have to offer.</p>
                  <p className="mb-0">If you are interested in hearing more about our company in private, please feel call me direct at (801) 381-3352. All inquiries will be kept in strict confidence. From our family to yours, we look forward to meeting you!
</p>
                </div>
              </div>
              <div className="py-1">
                <hr className="my-4" />
              </div>
              <div className="row ">
                <div className="col">
                  <h5 className="mb-1">Sincerely, Shawn B. Kenney</h5>
                  <span className="fs-sm">Broker/ Owner-In Depth Realty</span><br/>
                  <span className="fs-sm">801-381-3352</span>
                </div>
               
              </div>
            </div>
            <div className="col-md-5 offset-lg-1 thankyou-image mt-lg-0 mt-md-0 mt-sm-3 mt-3"><img className="d-block mx-auto img-round" src={Shawn} alt="Illustration" /></div>
          </div>
        </section>

         {/* <!-- Footer--> */} 
        <Footer />

      </main>

    </div>
  );
}
export default About;