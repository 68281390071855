import React from "react";
import { NavLink } from 'react-router-dom';
import photo1 from "./img/dark-logo.png";
function Header (){
    return(
        <header className="navbar navbar-expand-md navbar-transparent fixed-top">
            <div className="container-fluid px-lg-5 px-md-5 px-sm-2 px-2">
                <NavLink className="navbar-brand me-2 me-xl-4" to="/">
                <img className="d-block" src={photo1} width="116" alt="Finder" /></NavLink>
                <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse order-md-2" id="navbarNav">
                    <ul className="navbar-nav w-100">
                        <li className="nav-item"><NavLink to="/" className="nav-link text-dark">Home</NavLink></li>
                        <li className="nav-item"><NavLink to="/about" className="nav-link text-dark">Our Story</NavLink></li>
                        <li className="nav-item dropdown"><a className="nav-link text-dark dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">In Depth Realty</a>
                            <ul className="dropdown-menu">
                                <li><NavLink to="/commission" className="dropdown-item" >100% Commission</NavLink></li>
                                <li className="dropdown-divider"></li>
                                <li><NavLink to="/broker" className="dropdown-item" href="car-finder-home.html">Broker Support</NavLink></li>
                                <li className="dropdown-divider"></li>
                                <li><NavLink to="/training" className="dropdown-item">Agent Education</NavLink></li>
                                <li className="dropdown-divider"></li>
                                <li><NavLink to="/technology" className="dropdown-item">Technology</NavLink></li>
                                <li className="dropdown-divider"></li>
                                <li><NavLink to="/marketing" className="dropdown-item">Marketing</NavLink></li>
                                <li className="dropdown-divider"></li>
                                <li><NavLink to="/referral" className="dropdown-item">Referral</NavLink></li>
                            </ul>
                        </li>
                        <li className="nav-item"><NavLink to="/appointment"  className="nav-link text-dark">Appointment</NavLink></li>
                        <li className="nav-item"><NavLink to="/location" className="nav-link text-dark">Our Locations</NavLink></li>
                        <li className="nav-item dropdown">
                            <NavLink to="/contact-us"  className="nav-link text-dark dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Contact Us
                            </NavLink>
                            <ul className="dropdown-menu">
                                <li><NavLink to="/indepth-realty-corporate" className="dropdown-item" href="city-guide-home-v1.html"><i className="fi-map-pin fs-base opacity-50 me-2"></i>InDepth Realty Corporate </NavLink></li>
                                <li className="dropdown-divider"></li>
                                <li><NavLink to="/our-locations-south-jordan-office" className="dropdown-item" href="city-guide-home-v1.html"><i className="fi-map-pin fs-base opacity-50 me-2"></i>InDepth Realty Elite</NavLink></li>
                                <li className="dropdown-divider"></li>
                                <li><NavLink to="/our-locations-ogden-office" className="dropdown-item" href="city-guide-home-v1.html"><i className="fi-map-pin fs-base opacity-50 me-2"></i>Ogden Office</NavLink></li>
                                <li className="dropdown-divider"></li>
                                <li><NavLink to="/our-locations-saint-george-office" className="dropdown-item" href="city-guide-home-v1.html"><i className="fi-map-pin fs-base opacity-50 me-2"></i>Saint George Office</NavLink></li>
                                <li className="dropdown-divider"></li>
                                <li><NavLink to="/our-locations-orem-office" className="dropdown-item" href="city-guide-home-v1.html"><i className="fi-map-pin fs-base opacity-50 me-2"></i>Orem Office</NavLink></li>
                                <li className="dropdown-divider"></li>
                                <li><NavLink to="/park-city-office" className="dropdown-item" href="city-guide-home-v1.html"><i className="fi-map-pin fs-base opacity-50 me-2"></i>Park City Office</NavLink></li>
                                <li className="dropdown-divider"></li>
                                <li><NavLink to="/faq" className="dropdown-item" href="city-guide-home-v1.html"><i className="fi-map-pin fs-base opacity-50 me-2"></i>Faq</NavLink></li>
                                <li className="dropdown-divider"></li>
                            </ul>
                        </li>   

                        {/* <a class="btn btn-primary btn-sm rounded-pill ms-2 order-md-3" href="https://brokeragentbase.com/signin" target="_blank" rel="noopener">Login</a> */}
                    </ul>
                    <ul class="navbar-nav ml-md-auto">
                        <li>
                            <a class="btn btn-primary btn-md ms-2 order-md-3" href="https://brokeragentbase.com/signin" target="_blank" rel="noopener">Login</a>
                        </li>
                    </ul>
                </div>
            </div>
        </header> 
    )
}
export default Header;