
import React, { useEffect } from 'react';

import photo1 from "./img/logo.png";
import carousel1 from "./img/01.jpg";
import iphone from "./img/iphone.png";
import brokerimage from "./img/broker-image.png";
import indepthmackbook from "./img/Indepth-Macbook.png";
import getstarted from "./img/get-started.jpg";
import Header from "./header";
import { NavLink } from 'react-router-dom';
import Footer from "./footer";

function Broker() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
    return (
      <div>
     
    <main className="page-wrapper">
          <Header />

    <section class="container common_page_layout pb-5  mb-md-3">
        <div class="row align-items-center">
          <div class="col-md-12  text-center ">
            <h2 class="mb-md-4">We Care About Your Success</h2>
            <p class="fs-lg p-2 pb-md-3">At In Depth Realty, we care about your success as much as you do. With us you’re not just a number, you’re a member of our family. In Depth Realty offers easy broker access and support. Our support staff and brokers are available to help answer any and all of your questions. We’re also online so feel free to instant message, call, text, or pop-in at anytime!</p>
          </div>
        </div>
      </section>
      {/* <!-- How it works--> */}
      <section class="container mb-5 pt-5 pb-5 mt-4 pb-lg-4">
            <div class="row gy-4 align-items-center">
          <div class="col-md-5"><img class="d-block mx-auto img-round" src={getstarted} alt="Illustration"/></div>
          <div class="col-lg-7">
         
            <div class="steps steps-vertical">
              <div class="step active">
                <div class="step-progress"><span class="step-number"><i class="fa fa-check" aria-hidden="true"></i>
</span></div>
                <div class="step-label ms-4">
                <h2 class="h3 mb-lg-5 mb-sm-4">Let's Get Started</h2>
                  <p class="mb-0">Whether you’re newly licensed or a seasoned pro looking to grow your business, we’re eager and ready to help! We’ll tailor a plan around your schedule and speciﬁc to your needs.
                  Our website also has an amazing Agent Support Center Have a question? Agents have 24-hour access to our proprietary Agent Support Center that acts as a virtual broker. Thousands of our most frequently asked questions are available at their fingertips. Simply type your question or keyword and you will see a list of available documents and answers. </p>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </section>



      <section class="container mb-5 pt-5 pb-5 mt-4 pb-lg-4">
            <div class="row gy-4 align-items-center">

        <div class="col-md-6">
          <h2 class="h3 mt-5 pt-5">This easy-to-navigate tool is just one more beneﬁt you receive as an In Depth Realty Agent.</h2>

          
            </div>
        
        <div class="col-md-6">
            <img class="d-block mx-auto" src={indepthmackbook} alt="Illustration"/>
          </div>
          
        </div>
      </section>
      
      
      <section className="container-fluid pb-5 p-3 m-0 orange-bg">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <p className="mb-2 pb-2 mx-auto text-dark"><span className="left-horizontal-bar position-relative ps-5">JOIN In Depth Realty</span></p>
              <h2 className="mb-2">Schedule an Appointment​</h2>
              <p className="mb-lg-4 fs-lg text-muted">Come learn about why In Depth Realty is one of the most innovative brokerages in Utah! Please select one of the appointment options below. We’re available whether you’d like to meet in person, online, or chat on the phone</p>
              <h5 className="mb-2 text-center">SCHEDULE NOW!</h5>
            </div>
            <div class="col-md-5 col-lg-6">
              {/* <!-- Calendly inline widget begin --> */}
              <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/chatwithintegrarealty?primary_color=b1b1b1"
        style={{ minWidth: '320px', height: '880px' }}
      />
            </div>
            
            </div>
        </section>
          {/* <!-- Footer--> */}
          <Footer />
    </main>
     </div>
  );
}

export default Broker;