
import React, { useEffect } from 'react';
import indepthmackbook from "./img/Indepth-Macbook.png";
import educationsupport from "./img/training-indepth-realty.png";
import  videotraining from "./img/Video-Training.jpg";
import Header from "./header";
import Footer from "./footer";





import { NavLink } from 'react-router-dom';

function Training() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
    return (
      <div>
     
    <main className="page-wrapper">
          <Header />
    <section class="container common_page_layout pb-5  mb-md-3">
        <div class="row align-items-center">
          <div class="col-md-12  text-center ">
                <h2 class="mb-md-4">Education, Training & Support</h2>
          </div>
        </div>
      </section>

          <section class="container mb-5">
            <div class="row gy-4 align-items-center">
              <div class="col-md-6">
                <h5 class="">Every Agent Has Unique Goals</h5>
                <p class="mt-3">At In Depth Realty we know that every agent has unique goals in their real estate business. This is why we encourage one-on-one trainings. Our brokers and coaches dive into your business plan and assist you in every area of your practice. We also offer live classes in our office as well as 24/7 access to our online training on-demand library with more than 400 videos that include downloadable content for each class with new classes being added weekly. Our online classes also include guest presenters like Howard Brinton, Connie Podesta, Verl Workman, Bob Wolff and many others on subjects like technology, personal development, marketing, prospecting, team building, listings, and more.</p>
              </div>
              <div class="col-md-6">
                {/* <img class="d-block mx-auto" src={indepthmackbook} alt="Illustration" /> */}
                <img class="img-round" src={educationsupport} alt=""  sizes=""></img>
              </div>
            </div>
          </section>
          <section class="container mb-5 pt-5 pb-5">
            <div class="row gy-4 align-items-center">
              <div class="col-md-6 order-md-1 order-sm-2 order-2">
                <img decoding="async" class="img-round" src={videotraining} alt=""></img>
              </div>
              <div class="col-md-6 d-flex align-items-center justify-content-center order-md-2 order-sm-1 order-1">
                <p class="mt-3">Whether you’re newly licensed or a seasoned pro looking to grow your business, we’re eager and ready to help! We’ll tailor a plan around your schedule and speciﬁc to your needs. if you’re looking for in-house CE classes we also have plenty of that.just check our online training calendar for upcoming classes.</p>

                {/* <img class="d-block mx-auto" src={indepthmackbook} alt="Illustration" /> */}
              </div>
            </div>
          </section>

      {/* <!-- How it works--> */}
     
      <section className="container-fluid p-3 pb-5 m-0 orange-bg">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <p className="mb-2 pb-2 mx-auto text-dark"><span className="left-horizontal-bar position-relative ps-5">JOIN In Depth Realty</span></p>
              <h2 className="mb-2">Schedule an Appointment​</h2>
              <p className="mb-lg-4 fs-lg text-muted">Come learn about why In Depth Realty is one of the most innovative brokerages in Utah! Please select one of the appointment options below. We’re available whether you’d like to meet in person, online, or chat on the phone</p>
              <h5 className="mb-2 text-center">SCHEDULE NOW!</h5>
            </div>
            <div class="col-md-5 col-lg-6">
              {/* <!-- Calendly inline widget begin --> */}
              <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/chatwithintegrarealty?primary_color=b1b1b1"
        style={{ minWidth: '320px', height: '880px' }}
      />
            </div>
            
            </div>
        </section>
   
          {/* <!-- Footer--> */}
          <Footer />
    </main>
     </div>
  );
}

export default Training;