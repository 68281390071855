import React, { useEffect } from 'react';

import photo1 from "./img/logo.png";
import equalpaying from "./img/equal-pay.png";
import zerofees from "./img/zero-fee.png";
import marketing from "./img/marketing.png";
import commission from "./img/commision.png";
import support from "./img/support.png";
import technology from "./img/technology.png";
import training from "./img/training.png";
import office from "./img/office-space.png";
import bestoffer from "./img/we-offer.png";
import idahoimg from "./img/map.png";
import bannerimage from "./img/banner-hero_image.png";
import coverimage from "./img/infographics.png";
import iphone from "./img/iphone.png";
import officesolution from "./img/office-solution.png";
import digital from "./img/digital.png"; 
import mobileapp from "./img/mobile-app.png"; 
import ownlead from "./img/own-lead.png"; 
import refferalprogram from "./img/refferal-program.png";
import BrokerSupport from "./img/BrokerSupport.png";
import businesscard from "./img/business-card.png";








import linkedin from "./img/linkedin-round-icon.png";
import instagram from "./img/instagram.png";
import youtube from "./img/youtube-iconn.png"; 
import pinterest from "./img/pinterest-icon.png";




import threenine from "./img/39.jpg";
import fourty from "./img/40.jpg";
import fourtyone from "./img/41.jpg";
import fourtytwo from "./img/42.jpg";
import fourtythree from "./img/43.jpg";
import fourtyfour from "./img/44.jpg";
import fourtysix from "./img/46.jpg";
import fourtyseven from "./img/47.jpg";
import fourtyeight from "./img/48.jpg";
import idahomap from "./img/idaho.png";  
import { NavLink } from 'react-router-dom';
import Header from "./header";
import Footer from "./footer";


function Home() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <main>
        <Header />

        <section className="float-start w-100">
          {/* <!-- Parallax container--> */}
          <div className="jarallax float-start w-100" data-jarallax data-speed="0.5">
            {/* <!-- Parallax image--> */}
            <div className="jarallax-img bg-secondary float-start w-100" style={{
              background: `url(${bannerimage})`
            }}  ></div>
            {/* <!-- Section content--> */}
            <div className="container-fluid px-5 mt-5 pt-3">
              {/* <!-- Breadcrumb--> */}
              {/* <nav className="pt-3" aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light mb-lg-5">
                <li className="breadcrumb-item"><a href="city-guide-home-v1.html">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">About</li>
              </ol>
            </nav> */}
              {/* <!-- Text--> */}
              <div className="row">
                <div className="col-md-5 col-sm-8 py-md-5 py-4">
                  <p className="mb-2 pb-2 mx-auto text-dark"><span className="left-horizontal-bar position-relative ps-5">WHAT MAKES</span></p>
                  <h1 className="display-4 mb-2 pb-md-2 text-dark">In Depth Realty DIfferent ?</h1>
                  {/* <h2 className="text-dark">Experience, Innovation, Support</h2> */}
                  <p className="mb-4 pb-2 mx-auto fs-lg">We seek out experienced entrepreneurial spirits like you. We genuinely believe that your success is the centerpiece of ours. Our greatest desire is to equip you with the tech, tools, and training you need to reach the next level in your career. At In Depth Realty you’ll be part of a diverse group of experienced, like-minded, forward-thinking, tech-centric, and talented agents.</p>
                  <div className="d-flex align-items-center justify-content-start">
                    <a class="btn btn-md btn-primary" target="_blank" href="https://calendly.com/chatwithintegrarealty">JOIN US TO EXPERIENCE</a>
                   
                  </div>
                </div>
                {/* <div className="col-md-5 offset-md-2 col-sm-8 p-5 py-4 px-0 d-none d-lg-block d-md-block">
                  <img className="w-100" src={iphone} />
                </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Features--> */}
        <section className="services position-relative bg-white rounded-xxl-4 mb-5 pb-1 py-md-3 zindex-5 float-start w-100">
          <div className="container px-5 pt-5 mb-4 mb-md-5">
            <p className="mb-2 pb-2 mx-auto text-dark text-center"><span className="left-horizontal-bar position-relative ps-5">SERVICES</span></p>
            <h2 className="text-center">WHAT MAKES IN DEPTH REALTY DIFFERENT?</h2>
            <p className="mb-4 pb-2 mx-auto fs-lg text-center" >At In Depth Realty youll be part of a diverse group of experienced, like-minded, forward-<br/>thinking, tech-centric, and talented agents.</p>
            {/* <!-- Features grid--> */}
            <div className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 g-5 justify-content-center">
              {/* <!-- Feature item--> */}
              <div className="col">
                <div className="card border-0 shadow-sm bg-black h-100 rounded-0 ">
                  <div className="card-body icon-box">
                    <div className="mb-3">
                      <img className="d-block mb-3" src={equalpaying} height="45" alt="Bootstrap logo" />
                      </div>
                    <h3 className="h5 card-title text-light">EQUAL PAY</h3>
                    <p className="card-text fs-sm">Everyone is paid the same. There are no special deals. Everyone has exactly the same transaction fee plan.</p>
                  </div>
                </div>
              </div>
              {/* <!-- Feature item--> */}
              <div className="col">
                <div className="card border-0 shadow-sm bg-black h-100 rounded-0 ">
                  <div className="card-body icon-box">
                    <div className="mb-3">
                      <img className="d-block mb-3" src={zerofees} height="45" alt="Bootstrap logo" />
                      </div>
                    <h3 className="h5 card-title text-light">ZERO FEES</h3>
                    <p className="card-text fs-sm">Don't ever pay a monthly, annual, Franchise, Compliance, administrative or any other ongoing fee again.</p>
                  </div>
                </div>
              </div>
              {/* <!-- Feature item--> */}
              <div className="col">
                <div className="card border-0 shadow-sm bg-black h-100 rounded-0 ">
                  <div className="card-body icon-box">
                    <div className="mb-3">
                      <img className="d-block mb-3" src={marketing} height="45" alt="Bootstrap logo" />
                      </div>
                    <h3 className="h5 card-title text-light">MARKETING</h3>
                    <p className="card-text fs-sm">At In Depth Realty, keep more of your money without sacriﬁcing technology, broker support, education, or support. Don't give away another percentage of your hard-earned money.</p>
                  </div>
                </div>
              </div>
              {/* <!-- Feature item--> */}
              <div className="col">
                <div className="card border-0 shadow-sm bg-black h-100 rounded-0 ">
                  <div className="card-body icon-box">
                    <div className="mb-3">
                      <img className="d-block mb-3" src={commission} height="45" alt="Bootstrap logo" />
                      </div>
                    <h3 className="h5 card-title text-light">100% Commission</h3>
                    <p className="card-text fs-sm">Take home 100% of your commission on every transaction. Just a $595.00 transaction fee. Every penny you make is yours so you can invest back into your business, put money away, or take a trip.</p>
                  </div>
                </div>
              </div>
              {/* <!-- Feature item--> */}
              <div className="col">
                <div className="card border-0 shadow-sm bg-black h-100 rounded-0 ">
                  <div className="card-body icon-box">
                    <div className="mb-3">
                      <img className="d-block mb-3" src={technology} height="45" alt="Bootstrap logo" />
                      </div>
                    <h3 className="h5 card-title text-light">TRENDY Technology</h3>
                    <p className="card-text fs-sm">Our systems and technologies help agents spend less time with administrative tasks and more time building relationships and scaling their businesses. Our tech-centric philosophy attracts partners who help your deals close faster and safer than ever before.</p>
                  </div>
                </div>
              </div>
              {/* <!-- Feature item--> */}
              <div className="col">
                <div className="card border-0 shadow-sm bg-black h-100 rounded-0 ">
                  <div className="card-body icon-box">
                    <div className="mb-3">
                      <img className="d-block mb-3" src={training} height="45" alt="Bootstrap logo" />
                      </div>
                    <h3 className="h5 card-title text-light">Training</h3>
                    <p className="card-text fs-sm">We encourage one-on-one trainings. We also offer live classes as well as 24/7 access to our on-demand library with more than 400 videos that include downloadable content.</p>
                  </div>
                </div>
              </div>
              {/* <!-- Feature item--> */}
              <div className="col">
                <div className="card border-0 shadow-sm bg-black h-100 rounded-0 ">
                  <div className="card-body icon-box">
                    <div className="mb-3">
                      <img className="d-block mb-3" src={support} height="45" alt="Bootstrap logo" />
                      </div>
                    <h3 className="h5 card-title text-light">support</h3>
                    <p className="card-text fs-sm">In Depth Realty offers easy broker access and support. Our support staff and brokers are available to help answer any and all of your questions. We’re also online so feel free to instant message, call, text, or pop-in at anytime!</p>
                  </div>
                </div>
              </div>
              {/* <!-- Feature item--> */}
              <div className="col">
                <div className="card border-0 shadow-sm bg-black h-100 rounded-0 ">
                  <div className="card-body icon-box">
                    <div className="mb-3">
                      <img className="d-block mb-3" src={office} height="45" alt="Bootstrap logo" />
                      </div>
                    <h3 className="h5 card-title text-light">Office Space</h3>
                    <p className="card-text fs-sm">All of our locations are located in class A office space and are easy to locate with plenty of room to work and no desk fees. You can either come in and pick from any of the open station spaces. Reserve a conference whenever you need at no additional monthly fee.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Add business--> */}
        {/* <section className="container-fluid px-5 mb-5 pb-2 pb-lg-5">
          <div className="row align-items-lg-center gy-4">
            <div className="col-md-6"><img className="rounded-3 offer-image" src={bestoffer} alt="Cover" /></div>
            <div className="col-lg-5 offset-lg-1 col-md-6">
              <h2 className="mb-lg-4">What else we offer?</h2>
              <p className="mb-lg-4 fs-lg text-muted">At In DepthRealty you will be part of a diverse group of experienced, like-minded, forward-thinking, tech-centric, and talented agents.	</p>
              <ul className="list-unstyled mb-4 pb-lg-3">
                <li className="d-flex"><i className="fi-check mt-1 me-2 pe-1 text-primary"></i>100% commission splits</li>
                <li className="d-flex"><i className="fi-check mt-1 me-2 pe-1 text-primary"></i>We provide leads</li>
                <li className="d-flex"><i className="fi-check mt-1 me-2 pe-1 text-primary"></i>Leading lead Generation</li>
                <li className="d-flex"><i className="fi-check mt-1 me-2 pe-1 text-primary"></i>Digital Earnest Money</li>
                <li className="d-flex"><i className="fi-check mt-1 me-2 pe-1 text-primary"></i>Mobile App</li>
                <li className="d-flex"><i className="fi-check mt-1 me-2 pe-1 text-primary"></i>Referral Program</li>
              </ul><a className="btn btn-md btn-primary" href="city-guide-add-business.html"><i className="fi-plus me-2"></i>Add Business</a>
            </div>
          </div>
        </section> */}

        <section className="container-fluid px-5 mb-5 pb-2 pb-lg-5">
          <div className="row align-items-lg-center">
            <div className="col-lg-12 col-md-12 text-center">
              <h2 className="mb-lg-4 text-black">WITH In Depth Realty</h2>
              <p className="mb-lg-4 fs-lg text-black">Take home 100% of your commission on every transaction. Just a $595.00 transaction fee. Every penny you <br/>make is yours so you can invest back into your business, put money away, or take a trip.</p>
              <p className="mb-lg-4 fs-lg text-black">100% COMMISSION SPLITS <br/>You did the work. You keep the money.</p>
              <p className="mb-lg-4 fs-lg text-center">
                <span className="mb-lg-4 fs-lg bg-black text-light p-3 float-none w-25 m-auto d-block">TIRED OF “OLD SCHOOL” REAL ESTATE BROKERAGES? WE ARE TOO.</span>
              </p>
            </div>
            {/* <div className="col-lg-8 col-md-8 m-0 d-none d-lg-block d-md-block">
              <img className="rounded-3 offer-image" src={idahoimg} alt="Cover" />
            </div> */}
          </div>
        </section>

        <section className="container-fluid px-5 mb-5 pb-2 pb-lg-5">
          <div className="row align-items-lg-center">
            <div className="col-lg-6 col-md-6">
              <h2 className="mb-lg-4 text-black">COMING SOON, IDAHO. WE PROUDLY SERVE</h2>
              <p className="mb-lg-4 fs-lg text-black">Sick of paying a “Low Monthly Fee” to your brokerage just to be affiliated with them?<br /><br />
                With In Depth Realty you only pay a flat transaction fee when you have a closing. Stop paying fees to brokerages just to have your license with them.
                If they are not providing you with the tools, technology, and support you need to grow your business why are you paying them money every month if your not making them?<br /><br />
                In Depth Realty is your partner.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 m-0 d-none d-lg-block d-md-block">
              <img className="rounded-3 offer-image pull-right" src={idahomap} alt="Cover" />
            </div>
          </div>
        </section>

        <section className="container px-5 lead-generation mb-5 pb-2 pb-lg-5">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/ofImNthZpo0?si=8kFv3PNGdZ4c8jHc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className="col-lg-6 col-md-6">
              <h2 className="mb-lg-4">LEAD GENERATION</h2>
              <p className="mb-lg-4 fs-lg text-muted">We are proud to announce the partnership with the leading Lead Generation, CRM & Automation system Chime. Chime is an all-in-one CRM and sales acceleration platform designed to help you close more deals faster!<br /><br />
                The platform combines IDX websites, lead generation, marketing automation, advanced lead management, and powerful AI to capture and convert leads into new transactions.
              </p>
              <a className="btn btn-md btn-primary" target="_blank" href="https://calendly.com/chatwithintegrarealty"><i className="fi-plus me-2"></i>Get Started</a>
            </div>
          </div>
        </section>

        <section className="container px-5 lead-generation mb-5 pb-2 pb-lg-5">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6 order-md-1 order-sm-2 order-2">
              <h2 className="mb-2">WE PROVIDE LEADS</h2>
              <div className="mb-3">
                <a className="btn btn-sm btn-outline-primary" href="#">OPCITY</a>
              </div>
              <p className="mb-lg-4 fs-lg text-muted">OpCity is a lead referral platform that connects potential home buyers and sellers with a local agent via a live transfer after they have been screened by an OpCity representative.<br /><br />
                The best part about receiving leads through OpCity is that there are no upfront costs, you only pay a referral fee when the deal closes.
              </p>
              <a className="btn btn-md btn-primary" target="_blank" href="https://calendly.com/chatwithintegrarealty">Get Started</a>
            </div>
            <div className="col-lg-6 col-md-6 order-md-2 order-sm-2 order-1">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/vo1zEO2clfQ?si=u0C1sDV-rS7nIqGm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
        </section>

        <section className="container px-5 lead-generation mb-5 pb-2 pb-lg-5">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <img className="rounded-3" src={officesolution} alt="Cover" />
            </div>
            <div className="col-lg-6 col-md-6">
              <h2 className="mb-lg-4">OUR BACK OFFICE SOLUTION</h2>
              <p className="mb-lg-4 fs-lg text-muted">Allows our agents and staff to work in a secure, flexible intranet built for our specific needs.
                Designed to foster collaboration and transparency, our workspace’s versatile virtual features combine the connections of a social platform with improved workflows and operations. It’s truly and all in one platform.<br /><br />
                It encompasses key features our agents need to fully op… solution. Features such as transaction manager, company hub for all activities, events and industry news, synced calendars, links with brokerage recommend products and services like sign vendors, virtual tour companies, and more, document repository, contact database, training resources and tools for creating and sending marketing material.
              </p>
              <a className="btn btn-md btn-primary" target="_blank" href="https://calendly.com/chatwithintegrarealty">Get Started</a>
            </div>
          </div>
        </section>

        <section className="container px-5 lead-generation mb-5 pb-2 pb-lg-5">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6 order-md-1 order-sm-2 order-2">
              <h2 className="mb-2">DIGITAL</h2>
              <div className="mb-3">
                <a className="btn btn-sm btn-outline-primary" href="#">Earnest Money</a>
              </div>
              <p className="mb-lg-4 fs-lg text-muted">It shouldn’t take hours to collect your client’s earnest money. Switching to a digital experience is a faster and smarter alternative to checks and wires. Have your clients deposit their earnest money directly to our trust account via a secure mobile application. The App allows for the request, receipt, and refund of digital earnest money payments. We also allow earnest money to be deposited at your client’s Title company. It’s your choice.
              </p>
              <a href="https://calendly.com/chatwithintegrarealty" target="_blank" className="btn btn-md btn-primary">Get Started</a>
            </div>
            <div className="col-lg-6 col-md-6 order-md-2 order-sm-2 order-1">
              <img className="" src={digital} alt="Cover" />
            </div>
          </div>
        </section>

        <section className="container px-5 lead-generation mb-5 pb-2 pb-lg-5">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <img className="" src={mobileapp} alt="Cover" />
            </div>
            <div className="col-lg-6 col-md-6">
              <h2 className="mb-2">HOME SEARCH​</h2>
              <div className="mb-3">
                <a className="btn btn-sm btn-outline-primary" href="#">Mobile App</a>
              </div>
              <p>- MLS-based GPS home search app</p> 
              <p>- Custom branded with colors & logo.</p>
              <p>- Built-in CRM to keep track of client searches.</p>
              <p>- Instaview technology to get instant property info.</p>
              <p>- Automatic Facebook listing publishing</p>
              <a href="https://calendly.com/chatwithintegrarealty" className="btn btn-md btn-primary">Read More</a>
            </div>
         
          </div>
        </section>
        <section className="container px-5 lead-generation mb-5 pb-2 pb-lg-5">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6 order-md-1 order-sm-2 order-2">
              <h2 className="mb-2">How To Generate Your Own Leads</h2>
              <div className="mb-3">
                <a className="btn btn-sm btn-outline-primary" href="#">WE TEACH YOU</a>
              </div>
              <p className="mb-lg-4 fs-lg text-muted">Ready to grow your business through online leads? Will teach you how to use our technology to generate your own leads using Facebook, Google Adwords, Open Houses, & More, and then how to use our systems to automatically nurture and convert them into buyers and sellers.
              </p>
              <a href="https://calendly.com/chatwithintegrarealty" target="_blank" className="btn btn-md btn-primary">Get Started</a>
            </div>
            <div className="col-lg-6 col-md-6 order-md-2 order-sm-2 order-1">
              <img className="" src={ownlead} alt="Cover" />
            </div>
          </div>
        </section>

        <section className="container px-5 lead-generation mb-5 pb-2 pb-lg-5">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <img className="" src={refferalprogram} alt="Cover" />
            </div>
            <div className="col-lg-6 col-md-6">
              <h2 className="mb-2">Referral Program​</h2>
              <div className="mb-3">
                <a className="btn btn-sm btn-outline-primary" href="#">Agent</a>
              </div>
              <p className="mb-lg-4 fs-lg text-muted">We believe the most organic way to grow our brokerage is by word of mouth from our happy agents. So to reward our agents for referring others to join, For every agent that you refer to In Depth Realty that joins our company, Your annual cap will be reduced saving you even more money.</p>
              <a className="btn btn-md btn-primary">TERMS & CONDITIONS APPLY</a>
            </div>
          </div>
        </section>


        <section className="container px-5 lead-generation mb-5 pb-2 pb-lg-5">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6 order-md-1 order-sm-2 order-2">
              <h2 className="mb-2">Broker Support</h2>
            <p className="mb-lg-4 fs-lg text-muted">At In Depth Realty, we care about your success as much as you do. With us you’re not just a number, you’re a member of our family. In Depth Realty offers easy broker access and support 7 days a week. We even have a “Broker on Duty” link on our calendar so that you know who you can contact on weekends and holidays. Our support staff and brokers are available to help answer any and all of your questions. We’re also online so feel free to instant message, call, text, or pop in at any time!</p>
              <a href="https://calendly.com/chatwithintegrarealty" target="_blank" className="btn btn-md btn-primary">Get Started</a>
            </div>
            <div className="col-lg-6 col-md-6 order-md-2 order-sm-2 order-1">
              <img className="" src={BrokerSupport} alt="Cover" />
            </div>
          </div>
        </section>

        <section className="container px-5 lead-generation mb-5 pb-2 pb-lg-5">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <img className="" src={businesscard} alt="Cover" />
            </div>
            <div className="col-lg-6 col-md-6">
              <h2 className="mb-2">Smart Business Card​</h2>
              <p className="mb-lg-3 fs-lg text-muted">In Depth Realty offers cutting-edge contactless digital business cards that make it easy to share your contact info, social media accounts, profile, and more via text, email, Airdrop and more. Plus it will leave a lasting impression on the people you meet.</p>
              <p className="mb-lg-4 fs-lg text-muted">Say goodbye to traditional paper cards and say hello to a new way of connecting and a better position to win more clients and expand your network. Imagine being able to drop all your info into someone’s contract list with one click!</p>
              <a className="btn btn-md btn-primary">Read More</a>
            </div>
          </div>
        </section>

        <section className="container px-5 lead-generation mb-5 pb-2 pb-lg-5">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <p className="mb-2 pb-2 mx-auto text-dark"><span className="left-horizontal-bar position-relative ps-5">JOIN In Depth Realty</span></p>
              <h2 className="mb-2">Schedule an Appointment​</h2>
              <p className="mb-lg-4 fs-lg text-muted">Come learn about why In Depth Realty is one of the most innovative brokerages in Utah! Please select one of the appointment options below. We’re available whether you’d like to meet in person, online, or chat on the phone</p>
              <h5 className="mb-2 text-center">SCHEDULE NOW!</h5>
            </div>
            <div class="col-md-5 col-lg-6">
              {/* <!-- Calendly inline widget begin --> */}
              <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/chatwithintegrarealty?primary_color=b1b1b1"
        style={{ minWidth: '320px', height: '580px' }}
      />
            </div>
            
            </div>
        </section>





{/* 
        <section class="container-fluid px-5 pt-4 pt-md-5">
          <div class="d-sm-flex align-items-center justify-content-between">
            <h2 class="h3 mb-2 mb-sm-0">What sets In Depth Realty apart?</h2><a class="btn btn-link btn-light fw-normal px-0" href="#">How to sell cars on Finder<i class="fi-arrow-long-right fs-sm mt-0 ps-1 ms-2"></i></a>
          </div>
          <div class="row">
            <div class="col-md-5 col-lg-4 offset-lg-1 pt-4 mt-2 pt-md-5 mt-md-3">
              <div class="d-flex pb-4 pb-md-5 mb-2"><i class="fi-file lead text-primary mt-1 order-md-2"></i>
                <div class="text-md-end ps-3 ps-md-0 pe-md-3 order-md-1">
                  <h3 class="h6 mb-1">WE PROVIDE LEADS</h3>
                  <p class="fs-sm opacity-70 mb-0">OpCity is a lead referral platform that connects potential home buyers and sellers with a local agent via a live transfer after they have been screened by an OpCity representative.

                    The best part about receiving leads through OpCity is that there are no upfront costs.</p>
                </div>
              </div>
              <div class="d-flex pb-4 pb-md-5 mb-2"><i class="fi-search lead text-primary mt-1 order-md-2"></i>
                <div class="text-md-end ps-3 ps-md-0 pe-md-3 order-md-1">
                  <h3 class="h6 mb-1">Our Back Office Solution</h3>
                  <p class="fs-sm opacity-70 mb-0">Allows our agents and staff to work in a secure, flexible intranet built for our specific needs.

                    Designed to foster collaboration and transparency, our workspace’s versatile virtual features combine the connections of a social platform with improved workflows and operations.</p>
                </div>
              </div>
              <div class="d-flex pb-4 pb-md-5 mb-2"><i class="fi-settings lead text-primary mt-1 order-md-2"></i>
                <div class="text-md-end ps-3 ps-md-0 pe-md-3 order-md-1">
                  <h3 class="h6 mb-1">Earnest Money</h3>
                  <p class="fs-sm opacity-70 mb-0">It shouldn’t take hours to collect your client’s earnest money. Switching to a digital experience is a faster and smarter alternative to checks and wires. Have your clients deposit their earnest money directly to our trust account via a secure mobile application. The App allows for the request, receipt, and refund of digital earnest money payments. We also allow earnest money to be deposited at your client’s Title company. It’s your choice.</p>
                </div>
              </div>
            </div>
            <div class="col-md-2 d-none d-md-block">
              <div class="position-relative mx-auto h-100">
                <div class="rellax content-overlay pt-5" data-rellax-percentage="0.5"><img class="pt-3 mt-5" src={photo1} alt="Car" /></div>
              </div>
            </div>
            <div class="col-md-5 col-lg-4 pt-md-5 mt-md-3">
              <div class="d-flex pb-4 pb-md-5 mb-2"><i class="fi-info-circle lead text-primary mt-1"></i>
                <div class="ps-3">
                  <h3 class="h6 mb-1">MOBILE APP</h3>
                  <p class="fs-sm opacity-70 mb-0">MLS-based GPS home search app <br />
                    Custom branded with colors & logo.<br />
                    Built-in CRM to keep track of client searches.<br />
                    Instaview technology to get instant property info. <br />
                    Automatic Facebook listing publishing</p>
                </div>
              </div>
              <div class="d-flex pb-4 pb-md-5 mb-2"><i class="fi-users lead text-primary mt-1"></i>
                <div class="ps-3">
                  <h3 class="h6 mb-1">How To Generate Your Own Leads</h3>
                  <p class="fs-sm opacity-70 mb-0">Ready to grow your business through online leads? Will teach you how to use our technology to generate your own leads using Facebook, Google Adwords, Open Houses, & More, and then how to use our systems to automatically nurture and convert them into buyers and sellers.</p>
                </div>
              </div>
              <div class="d-flex pb-4 pb-md-5 mb-2"><i class="fi-calculator lead text-primary mt-1"></i>
                <div class="ps-3">
                  <h3 class="h6 mb-1">Referral Program</h3>
                  <p class="fs-sm opacity-70 mb-0">We believe the most organic way to grow our brokerage is by word of mouth from our happy agents. So to reward our agents for referring others to join, For every agent that you refer to Integra Realty that joins our company, Your annual cap will be reduced saving you even more money.</p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section class="container-fluid px-5 mb-5 pb-lg-5 pb-md-4">
          <div class="card py-lg-5 py-4 border-0 bg-secondary">
            <div class="card-body p-4 text-center">
              <h2>Meet our agents</h2>
              <p class="mx-auto mb-md-5 mb-4 pb-lg-3" >We have the most comprehensive directory of estate agents to help you with all your property needs. Whether buying, selling or renting start your search to find the right local expertise.</p>
              <div class="our_agents d-flex align-items-center justify-content-center flex-wrap mb-md-5 mb-4 pb-lg-3 ps-3 pt-4">
                <div class="border border-5 border-white rounded-circle bg-white ms-n3 mt-n4"><img class="rounded-circle" src={threenine} width="110" alt="Avatar" /></div>
                <div class="border border-5 border-white rounded-circle bg-white ms-n3 mt-n4"><img class="rounded-circle" src={fourty} width="110" alt="Avatar" /></div>
                <div class="border border-5 border-white rounded-circle bg-white ms-n3 mt-n4"><img class="rounded-circle" src={fourtyone} width="110" alt="Avatar" /></div>
                <div class="border border-5 border-white rounded-circle bg-white ms-n3 mt-n4"><img class="rounded-circle" src={fourtytwo} width="110" alt="Avatar" /></div>
                <div class="border border-5 border-white rounded-circle bg-white ms-n3 mt-n4"><img class="rounded-circle" src={fourtythree} width="110" alt="Avatar" /></div>
                <div class="border border-5 border-white rounded-circle bg-white ms-n3 mt-n4"><img class="rounded-circle" src={fourtyfour} width="110" alt="Avatar" /></div>
                <div class="border border-5 border-white rounded-circle bg-white ms-n3 mt-n4"><img class="rounded-circle" src={fourtysix} width="110" alt="Avatar" /></div>
                <div class="border border-5 border-white rounded-circle bg-white ms-n3 mt-n4"><img class="rounded-circle" src={fourtyseven} width="110" alt="Avatar" /></div>
                <div class="border border-5 border-white rounded-circle bg-white ms-n3 mt-n4"><img class="rounded-circle" src={fourtyeight} width="110" alt="Avatar" /></div>
              </div><a class="btn btn-lg btn-primary w-sm-auto w-100" href="#">View all agents<i class="fi-chevron-right ms-2"></i></a>
            </div>
          </div>
        </section> */}

        {/* <section class="container mt-n3 mt-md-0 mb-5 pb-lg-4">
        <h2 class="h3 mb-4 pb-2">What s new in Berlin</h2>
        <div class="tns-carousel-wrapper">
          <div class="tns-carousel-inner" data-carousel-options="{&quot;mode&quot;: &quot;gallery&quot;, &quot;nav&quot;: false, &quot;controlsContainer&quot;: &quot;#carousel-controls&quot;}">
            <div>
              <div class="row">
                <div class="col-md-7 mb-md-0 mb-3"><img class="position-relative rounded-3 zindex-5" src={idahoimg} alt="Article image"/></div>
                <div class="col-md-5">
                  <h3 class="h4 from-top">Amusement Park</h3>
                  <ul class="list-unstyled delay-2 from-end">
                    <li class="mb-1 fs-sm"><i class="fi-map-pin text-muted me-2 fs-base"></i>Ollenhauer Str. 29, 10118</li>
                    <li class="mb-1 fs-sm"><i class="fi-clock text-muted me-2 fs-base"></i>9:00 23:00</li>
                    <li class="mb-1 fs-sm"><i class="fi-wallet text-muted me-2 fs-base"></i>$$</li>
                  </ul>
                  <p class="pb-2 delay-3 from-end d-none d-lg-block">Blandit lorem dictum faucibus mauris pretium enim. Risus.</p>
                  <div class="delay-4 scale-up"><a class="btn btn-primary rounded-pill" href="city-guide-single.html">View more<i class="fi-chevron-right fs-sm ms-2"></i></a></div>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-md-7 mb-md-0 mb-3"><img class="position-relative rounded-3 zindex-5" src={idahoimg} alt="Article image"/></div>
                <div class="col-md-5">
                  <h3 class="h4 from-top">Mall of Berlin</h3>
                  <ul class="list-unstyled delay-2 from-end">
                    <li class="mb-1 fs-sm"><i class="fi-map-pin text-muted me-2 fs-base"></i>Ollenhauer Str. 29, 10118</li>
                    <li class="mb-1 fs-sm"><i class="fi-clock text-muted me-2 fs-base"></i>10:00  20:00</li>
                    <li class="mb-1 fs-sm"><i class="fi-wallet text-muted me-2 fs-base"></i>$$</li>
                  </ul>
                  <p class="pb-2 delay-3 from-end d-none d-lg-block">Sem nibh urna id arcu. Quis tortor vestibulum morbi volutpat. Et duis et sed tellus. Egestas ultrices viverra in pretium nec. Dui ornare fusce vel fringilla scelerisque posuere pharetra ut. Dui donec sapien, dictum nunc varius.</p>
                  <div class="delay-4 scale-up"><a class="btn btn-primary rounded-pill" href="city-guide-single.html">View more<i class="fi-chevron-right fs-sm ms-2"></i></a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div class="tns-carousel-controls pt-2 mt-4" id="carousel-controls">
          <button class="me-3" type="button"><i class="fi-chevron-left fs-xs"></i></button>
          <button type="button"><i class="fi-chevron-right fs-xs"></i></button>
        </div>
      </section>  */}






        {/* <section className="container mb-5 pb-lg-5">
        <h2 className="mb-sm-4 pb-md-2 text-center">Frequently asked questions</h2>
       
        <div className="row row-cols-lg-3 row-cols-sm-2 row-cols-1 g-sm-4 g-0">
        
          <div className="col">
            <div className="card border-0 h-100">
              <div className="card-body">
                <h3 className="h5 text-primary">01. Getting started</h3><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Quis arcu euismod est, varius nisi?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Aliquam, commodo sed nibh?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Faucibus felis fames mauris dolor purus?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Odio diam, tellus facilisi?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Eu quisque libero, sed neque?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Lectus sed sit cursus amet, vitae tempor?</a>
              </div>
            </div>
          </div>
         
          <div className="col">
            <div className="card border-0 h-100">
              <div className="card-body">
                <h3 className="h5 text-primary">02.Partnership</h3><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Tincidunt molestie scelerisque?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Nunc, ipsum ut augue fusce ornare?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Velit, mauris amet feugiat condimentum mollis?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Lacus, sed fames mi cras id?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Adipiscing magna suspendisse?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Libero pellentesque ultricies maecenas?</a>
              </div>
            </div>
          </div>
 
          <div className="col">
            <div className="card border-0 h-100">
              <div className="card-body">
                <h3 className="h5 text-primary">03. Account &amp; profile</h3><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Cursus fusce volutpat tempor?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">A senectus auctor sodales gravida non elit?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Mauris accumsan vel rhoncus?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Cras nunc, semper lectus turpis?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Pulvinar sed morbi massa nulla dolor eget?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Bibendum mattis amet amet?</a>
              </div>
            </div>
          </div>
         
          <div className="col">
            <div className="card border-0 h-100">
              <div className="card-body">
                <h3 className="h5 text-primary">04. Privacy &amp; security</h3><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Pellentesque ante quisque sit?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Eu quam bibendum adipiscing leo?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Massa fermentum, eget nec elementum?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">In quis pulvinar amet morbi praesent?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Eros, dolor in consequat netus?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Quis gravida felis dui non gravida?</a>
              </div>
            </div>
          </div>
        
          <div className="col">
            <div className="card border-0 h-100">
              <div className="card-body">
                <h3 className="h5 text-primary">05. Transactions</h3><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Nisl urna id aliquam sed vitae pellentesque?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Nam morbi faucibus ultricies euismod?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Amet scelerisque consectetur consequa?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Sapien at fames urna in id at?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Fames id nullam et id proin ante ultrices?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Quis ultricies turpis commodo, elit lectus?</a>
              </div>
            </div>
          </div>
         
          <div className="col">
            <div className="card border-0 h-100">
              <div className="card-body">
                <h3 className="h5 text-primary">06. Privacy &amp; security</h3><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Lacus eget amet blandit egestas?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Augue at donec molestie vulputate cum?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Viverra adipiscing nisl, lacus at et?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Sagittis dictum hendrerit faucibus malesuada?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Tempus est non sapien tempus?</a><a className="nav-link mb-2 p-0 fw-normal" href="city-guide-help-center.html">Nunc est hendrerit volutpat nunc donec?</a>
              </div>
            </div>
          </div>
        </div>
        </section> */}

        {/* <section className="container mb-5 pb-2 pb-lg-5">
        <h2 className="mb-4 text-center">The people behind Finder</h2>
      
        <div className="tns-carousel-wrapper tns-controls-outside-xxl tns-nav-outside tns-nav-outside-flush mx-n2">
          <div className="tns-carousel-inner row gx-4 mx-0 pt-3 pb-4" data-carousel-options="{&quot;items&quot;: 4, &quot;responsive&quot;: {&quot;0&quot;:{&quot;items&quot;:1},&quot;500&quot;:{&quot;items&quot;:2},&quot;768&quot;:{&quot;items&quot;:3},&quot;992&quot;:{&quot;items&quot;:4}}}">
           
            <div className="col">
              <div className="card border-0 shadow-sm"><img className="card-img-top" src="img/city-guide/about/team/01.jpg" alt="Devon Lane"/>
                <div className="card-body text-center">
                  <h3 className="h5 card-title mb-2">Devon Lane</h3><span className="d-inline-block mb-3 fs-sm">President &amp; CEO</span>
                  <div className="pt-1"><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-facebook"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-twitter"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-instagram"></i></a></div>
                </div>
              </div>
            </div>
          
            <div className="col">
              <div className="card border-0 shadow-sm"><img className="card-img-top" src="img/city-guide/about/team/02.jpg" alt="Dianne Russell"/>
                <div className="card-body text-center">
                  <h3 className="h5 card-title mb-2">Dianne Russell</h3><span className="d-inline-block mb-3 fs-sm">Chief Financial Officer</span>
                  <div className="pt-1"><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-facebook"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-twitter"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-instagram"></i></a></div>
                </div>
              </div>
            </div>
           
            <div className="col">
              <div className="card border-0 shadow-sm"><img className="card-img-top" src="img/city-guide/about/team/03.jpg" alt="Ronald Richards"/>
                <div className="card-body text-center">
                  <h3 className="h5 card-title mb-2">Ronald Richards</h3><span className="d-inline-block mb-3 fs-sm">Technology Officer</span>
                  <div className="pt-1"><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-facebook"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-twitter"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-instagram"></i></a></div>
                </div>
              </div>
            </div>
           
            <div className="col">
              <div className="card border-0 shadow-sm"><img className="card-img-top" src="img/city-guide/about/team/04.jpg" alt="RAlbert Flores"/>
                <div className="card-body text-center">
                  <h3 className="h5 card-title mb-2">Albert Flores</h3><span className="d-inline-block mb-3 fs-sm">Commercial Officer</span>
                  <div className="pt-1"><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-facebook"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-twitter"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-instagram"></i></a></div>
                </div>
              </div>
            </div>
         
            <div className="col">
              <div className="card border-0 shadow-sm"><img className="card-img-top" src="img/city-guide/about/team/01.jpg" alt="Devon Lane"/>
                <div className="card-body text-center">
                  <h3 className="h5 card-title mb-2">Devon Lane</h3><span className="d-inline-block mb-3 fs-sm">President &amp; CEO</span>
                  <div className="pt-1"><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-facebook"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-twitter"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-instagram"></i></a></div>
                </div>
              </div>
            </div>
      
            <div className="col">
              <div className="card border-0 shadow-sm"><img className="card-img-top" src="img/city-guide/about/team/02.jpg" alt="Dianne Russell"/>
                <div className="card-body text-center">
                  <h3 className="h5 card-title mb-2">Dianne Russell</h3><span className="d-inline-block mb-3 fs-sm">Chief Financial Officer</span>
                  <div className="pt-1"><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-facebook"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-twitter"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-instagram"></i></a></div>
                </div>
              </div>
            </div>
        
            <div className="col">
              <div className="card border-0 shadow-sm"><img className="card-img-top" src="img/city-guide/about/team/03.jpg" alt="Ronald Richards"/>
                <div className="card-body text-center">
                  <h3 className="h5 card-title mb-2">Ronald Richards</h3><span className="d-inline-block mb-3 fs-sm">Technology Officer</span>
                  <div className="pt-1"><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-facebook"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-twitter"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-instagram"></i></a></div>
                </div>
              </div>
            </div>
           
            <div className="col">
              <div className="card border-0 shadow-sm"><img className="card-img-top" src="img/city-guide/about/team/04.jpg" alt="RAlbert Flores"/>
                <div className="card-body text-center">
                  <h3 className="h5 card-title mb-2">Albert Flores</h3><span className="d-inline-block mb-3 fs-sm">Commercial Officer</span>
                  <div className="pt-1"><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-facebook"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-twitter"></i></a><a className="btn btn-icon btn-light-primary btn-xs rounded-circle shadow-sm mx-2" href="#"><i className="fi-instagram"></i></a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>  */}

        {/*<section className="container mb-5 pb-2 pb-lg-5">
        <h2 className="mb-4 pb-2 text-center">World city guides</h2><img src="img/city-guide/about/map.jpg" alt="World map"/>
        </section>
    
      <section className="container my-5 py-lg-4">
            <div className="d-sm-flex align-items-center justify-content-between mb-4 pb-2">
            <h2 className="h3 mb-sm-0"> miss the latest posts</h2><a className="btn btn-link fw-normal ms-sm-3 p-0" href="city-guide-blog.html">Go to blog<i className="fi-arrow-long-right ms-2"></i></a>
            </div>
    
        <div className="tns-carousel-wrapper tns-nav-outside mb-md-2"> 
          <div className="tns-carousel-inner d-block" data-carousel-options="{&quot;controls&quot;: false, &quot;gutter&quot;: 24, &quot;autoHeight&quot;: true, &quot;responsive&quot;: {&quot;0&quot;:{&quot;items&quot;:1,&quot;nav&quot;:true},&quot;500&quot;:{&quot;items&quot;:2},&quot;850&quot;:{&quot;items&quot;:3},&quot;1200&quot;:{&quot;items&quot;:3}}}">
          
            <article><a className="d-block mb-3" href="city-guide-blog-single.html"><img className="rounded-3" src="img/city-guide/blog/01.jpg" alt="Post image"/></a><a className="fs-xs text-uppercase text-decoration-none" href="#">Travelling</a>
              <h3 className="fs-base pt-1"><a className="nav-link" href="city-guide-blog-single.html">Air Travel in the Time of COVID-19</a></h3><a className="d-flex align-items-center text-decoration-none" href="#"><img className="rounded-circle" src="img/avatars/16.png" width="44" alt="Avatar"/>
                <div className="ps-2">
                  <h6 className="fs-sm text-nav lh-base mb-1">Bessie Cooper</h6>
                  <div className="d-flex text-body fs-xs"><span className="me-2 pe-1"><i className="fi-calendar-alt opacity-70 mt-n1 me-1 align-middle"></i>May 24</span><span><i className="fi-chat-circle opacity-70 mt-n1 me-1 align-middle"></i>No comments</span></div>
                </div></a>
            </article>
          
            <article><a className="d-block mb-3" href="city-guide-blog-single.html"><img className="rounded-3" src="img/city-guide/blog/02.jpg" alt="Post image"/></a><a className="fs-xs text-uppercase text-decoration-none" href="#">Entertainment</a>
              <h3 className="fs-base pt-1"><a className="nav-link" href="city-guide-blog-single.html">10 World-className Museums You Can Visit Online</a></h3><a className="d-flex align-items-center text-decoration-none" href="#"><img className="rounded-circle" src="img/avatars/18.png" width="44" alt="Avatar"/>
                <div className="ps-2">
                  <h6 className="fs-sm text-nav lh-base mb-1">Annette Black</h6>
                  <div className="d-flex text-body fs-xs"><span className="me-2 pe-1"><i className="fi-calendar-alt opacity-70 mt-n1 me-1 align-middle"></i>Apr 28</span><span><i className="fi-chat-circle opacity-70 mt-n1 me-1 align-middle"></i>4 comments</span></div>
                </div></a>
            </article>
         
            <article><a className="d-block mb-3" href="city-guide-blog-single.html"><img className="rounded-3" src="img/city-guide/blog/03.jpg" alt="Post image"/></a><a className="fs-xs text-uppercase text-decoration-none" href="#">Travelling</a>
              <h3 className="fs-base pt-1"><a className="nav-link" href="city-guide-blog-single.html">7 Tips for Solo Travelers in Africa</a></h3><a className="d-flex align-items-center text-decoration-none" href="#"><img className="rounded-circle" src="img/avatars/17.png" width="44" alt="Avatar"/>
                <div className="ps-2">
                  <h6 className="fs-sm text-nav lh-base mb-1">Ralph Edwards</h6>
                  <div className="d-flex text-body fs-xs"><span className="me-2 pe-1"><i className="fi-calendar-alt opacity-70 mt-n1 me-1 align-middle"></i>Apr 15</span><span><i className="fi-chat-circle opacity-70 mt-n1 me-1 align-middle"></i>2 comments</span></div>
                </div></a>
            </article>
          </div>
        </div>
      </section>

      
    
      <section className="container mb-5 pb-lg-5">
        <div className="rounded-3 bg-faded-accent py-5 px-sm-5 px-4">
          <div className="mx-auto py-md-4 text-center" >
            <h2>Stay informed</h2>
            <p className="mb-4 pb-2 fs-lg">Subscribe to our newsletter, be the first to see the latest news &amp; advice.</p>
            <form className="form-group rounded-pill mb-3">
              <div className="input-group input-group-lg"><span className="input-group-text text-muted"><i className="fi-mail"></i></span>
                <input className="form-control" type="email" placeholder="Your email"/>
              </div>
              <button className="btn btn-primary btn-lg rounded-pill" type="button">Subscribe</button>
            </form>
            <div className="form-text">* By signing up you agree to our <a href='#' className='text-decoration-none'>Privacy Policy</a></div>
          </div>
        </div>
      </section> */}

        {/* <img className="w-100" src={coverimage} alt="Cover" /> */}
        {/* <!-- Footer--> */}
        <Footer />
      </main>

        </div>
  );
}

export default Home;
