
import React, { useEffect } from 'react';
import photo1 from "./img/logo.png";
import carousel1 from "./img/01.jpg";
import iphone from "./img/iphone.png";
import videotraining from "./img/Video-Training.jpg";
import monthlyfee from "./img/Monthly-Fee.png";
import agenttool from "./img/Agent-Tools.png";
import { NavLink } from 'react-router-dom';
import Header from "./header";
import Footer from "./footer";


function Commission() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);


  return (
    <div>
      <main className="page-wrapper">
        <Header />

        <section class="container common_page_layout pb-5  mb-md-3">
          <div class="row align-items-center">
            <div class="col-md-12 text-center">
              <h2 class="mb-md-4">In Depth's Commissions Plan</h2>
              <p class="fs-lg p-2 pb-md-3"><b>100% Commission: </b>ln Depth Realty is a full-service brokerage that offers the industries best commission plans. In Depth Realty started in Utah in 2002 and is the original 100% commission brokerage in the state. We have been offering our 100%, transaction-based model longer than any other brokerage in Utah. No more splitting commissions with brokers, monthly fees or other junk fees…</p>
            </div>
          </div>
        </section>
        {/* <!-- How it works--> */}
        <section class="container mb-5 pt-5 pb-5 mt-4 pb-lg-4">
          <div class="row gy-4 align-items-center">
            <div class="col-md-6"><img class="d-block mx-auto commission-image" src={monthlyfee} alt="Illustration" /></div>
            <div class="col-lg-6">

              <div class="steps steps-vertical">
                <div class="step active">
                  <div class="step-progress"><span class="step-number"><i class="fa fa-check" aria-hidden="true"></i>
                  </span></div>
                  <div class="step-label ms-4">
                    <h2 class="h3 mb-lg-5 mb-sm-4">100% COMMISSION SPLITS</h2>
                    <p class="mb-0">On each transaction, In Depth Realty is compensated with a $495 transaction fee. Our agents keep everything else and to make things even better, there are no monthly fees, start-up fees or buyer or seller fees! A lot of our 100% commission competitors tout low transaction fees and then add layers of other fees, effectively nickel and diming an agent or even charging fees to the agents clients to make up for the lower transaction fee.
                      At lndepthrealty we pride ourselves on hiring only experienced agents who have been licensed for a minimum 2 years or have closed at least 12 transactions since being licensed. Our agents must also be dedicated to real estate with a clean track record and have a great reputation in our industry, have superior customer service, high ethical standards and must be in good standing with the Board of REALTORS. This does not mean we only hire full time agents. We hire experienced agent who have a desire to grow their business. if an agent is willing to invest in their business, then we are willing to invest in them. At In Depth Realty we strive to be the best!</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section class="container mb-5 pt-5 pb-5 mt-4 pb-lg-4">
          <div class="row gy-4 align-items-center">
            <div class="col-md-6 order-md-1 order-sm-2 order-2">
              <div class="steps steps-vertical">
                <div class="step active">
                  <div class="step-progress"><span class="step-number"><i class="fa fa-check" aria-hidden="true"></i></span></div>
                  <div class="step-label ms-4">
                    <h2 class="h3 mb-lg-5 mb-sm-4">Broker Support</h2>
                    <p class="mb-0">At In Depth Realty, we care about your success as much as you do. With us you’re not just a number, you’re a member of our family. In Depth Realty offers easy broker access and support. Our support staff and brokers are available to help answer any and all of your questions. We’re also online so feel free to instant message, call, text, or pop-in at anytime!</p>
                    <p class="mb-0">Whether you’re newly licensed or a seasoned pro looking to grow your business, we’re eager and ready to help! We’ll tailor a plan around your schedule and speciﬁc to your needs.</p>
                    <p class="mb-0">Our website also has an amazing Agent Support Center Have a question? Agents have 24-hour access to our proprietary Agent Support Center that acts as a virtual broker. Thousands of our most frequently asked questions are available at their fingertips. Simply type your question or keyword and you will see a list of available documents and answers.
                      This easy-to-navigate tool is just one more beneﬁt you receive as an In Depth Realty Agent.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 order-md-2 order-sm-21 order-1">
              <img class="d-block mx-auto choose-property-image" src={agenttool} alt="Illustration" />
            </div>
          </div>
        </section>

        <section class="container mb-5 pt-5 pb-5 mt-4 pb-lg-4">
          <div class="row gy-4 align-items-center">
            <div class="col-md-6"><img class="d-block mx-auto education-support-image img-round" src={videotraining} alt="Illustration" /></div>
            <div class="col-md-6">
              <div class="steps steps-vertical">
                <div class="step active">
                  <div class="step-progress"><span class="step-number"><i class="fa fa-check" aria-hidden="true"></i></span></div>
                  <div class="step-label ms-4">
                    <h2 class="h3 mb-lg-5 mb-sm-4">Education, Training & Support</h2>
                    <p class="mb-0">At In Depth Realty we know that every agent has unique goals in their real estate business. This is why we encourage one-on-one trainings. Our brokers and coaches dive into your business plan and assist you in every area of your practice. We also offer live classes in our office as well as 24/7 access to our online training on-demand library with more than 400 videos that include downloadable content for each class with new classes being added weekly. Our online classes also include guest presenters like Howard Brinton, Connie Podesta, Verl Workman, Bob Wolff and many others on subjects like technology, personal development, marketing, prospecting, team building, listings, and more.</p>
                    <p class="mb-0">Whether you’re newly licensed or a seasoned pro looking to grow your business, we’re eager and ready to help! We’ll tailor a plan around your schedule and speciﬁc to your needs. if you’re looking for in-house CE classes we also have plenty of that.just check our online training calendar for upcoming classes.</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

       <section className="container-fluid pb-5 p-3 m-0 orange-bg">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <p className="mb-2 pb-2 mx-auto text-dark"><span className="left-horizontal-bar position-relative ps-5">JOIN In Depth Realty</span></p>
              <h2 className="mb-2">Schedule an Appointment​</h2>
              <p className="mb-lg-4 fs-lg text-muted">Come learn about why In Depth Realty is one of the most innovative brokerages in Utah! Please select one of the appointment options below. We’re available whether you’d like to meet in person, online, or chat on the phone</p>
              <h5 className="mb-2 text-center">SCHEDULE NOW!</h5>
            </div>
            <div class="col-md-5 col-lg-6">
              {/* <!-- Calendly inline widget begin --> */}
              <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/chatwithintegrarealty?primary_color=b1b1b1"
        style={{ minWidth: '320px', height: '880px' }}
      />
            </div>
            
            </div>
        </section>
        {/* <!-- Footer--> */}
        <Footer />
      </main>
    </div>
  );
}

export default Commission;