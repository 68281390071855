
import React, { useEffect } from 'react';
import photo1 from "./img/logo.png";
import carousel1 from "./img/01.jpg";
import iphone from "./img/iphone.png";
import brokerimage from "./img/broker-image.png";
import indepthmackbook from "./img/Indepth-Macbook.png";
import ipad from "./img/IPAD.png";
import OwnMarketing from "./img/Own-Marketing.jpg";
import Header from "./header";
import Footer from "./footer";
import { NavLink } from 'react-router-dom';

function Marketing() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

    return (
      <div>
     
    <main className="page-wrapper">
          <Header />
 
    {/* <section class="container common_page_layout pb-5  mb-md-3">
        <div class="row align-items-center">
          <div class="col-md-12  text-center ">
            <h2 class="mb-md-4">We Care About Your Success</h2>
            <p class="fs-lg p-2 pb-md-3">At In Depth Realty, we care about your success as much as you do. With us you’re not just a number, you’re a member of our family. In Depth Realty offers easy broker access and support. Our support staff and brokers are available to help answer any and all of your questions. We’re also online so feel free to instant message, call, text, or pop-in at anytime!</p>
          </div>
        </div>
      </section> */}
      {/* <!-- How it works--> */}
      <div className="common_page_layout">
        <section class="container mb-5 pt-5 mt-4 pb-lg-4">
              <div class="row gy-4 align-items-center">
            <div class="col-md-6">
              <h4>Market Yourself</h4>
              <h2 class="h3">Personalized Website and Email Marketing System</h2>
              <p className="mb-0 pb-md-3 text-dark opacity-70">
                All In Depth Realty agents receive a customizable IDX-enabled website so your clients can search for homes.
              </p>
              <p className="mb-0 pb-md-3 text-dark opacity-70">
                You can edit pages, add your biography and photos, and write blog content.
              </p>
              <p className="mb-0 pb-md-3 text-dark opacity-70">
                On the back-end of the site is a login with a fully featured client relationship management system. Keep track of all of your leads and follow ups in one location! Lastly, there is an email marketing system built in so you can put your contacts on regular messaging schedules. Start a client newsletter or send birthday greetings!
              </p>
              <h5>BECOME AN INDEPTH MEMBER</h5>
            </div>
            <div class="col-md-6">
              <img class="d-block mx-auto img-round" src={ipad} alt="Illustration" />
              {/* <img class="d-block mx-auto mt-3" src="https://movetointegra.com/wp-content/uploads/4-2-e1677597776677.jpg" alt="Illustration" /> */}

            </div>
          </div>
        </section>
      <section class="container mb-5 pt-5 mt-4 pb-lg-4">
              <div class="row gy-4 align-items-center">
                <div class="col-md-6 order-md-1 order-sm-2 order-2">
                <img class="d-block mx-auto img-round" src={OwnMarketing} alt="Illustration" />
              </div>
                <div class="col-md-6 order-md-2 order-sm-1 order-1">
                <h4>WITH INDEPTH</h4>
                <h2 class="h3">Personal Marketing Center</h2>
                <p className="mb-0 pb-md-3 text-dark opacity-70">
                  Our Dynamic Marketing tools are IDX linked to the Wasatch Front and Park City MLS systems so our agents can creates marketing materials with a click of a button. The tool integrates the address, agent contact info and photo, property description, and photos of the requested listing.
                </p>
                <p className="mb-0 pb-md-3 text-dark opacity-70">
                  You can create fresh, up-to-date marketing pieces in 5 easy steps with our IDX integration or you can easily access previous marketing pieces for a quick refresh or additional printing!
                </p>
                <p className="mb-0 pb-md-3 text-dark opacity-70">
                  Enhanced company flyers, property websites, target mail/email campaigns are included. This eliminates the need for several systems, and therefore, several data entry to points. We can help you manage all aspects of your business needs in our all-in-one system.
               </p>
                <h5>CUSTOMIZED MARKETING TOOLS</h5>
            </div>
      
        </div>
      </section>
      <section className="container-fluid pb-5 p-3 m-0 orange-bg">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <p className="mb-2 pb-2 mx-auto text-dark"><span className="left-horizontal-bar position-relative ps-5">JOIN In Depth Realty</span></p>
              <h2 className="mb-2">Schedule an Appointment​</h2>
              <p className="mb-lg-4 fs-lg text-muted">Come learn about why In Depth Realty is one of the most innovative brokerages in Utah! Please select one of the appointment options below. We’re available whether you’d like to meet in person, online, or chat on the phone</p>
              <h5 className="mb-2 text-center">SCHEDULE NOW!</h5>
            </div>
            <div class="col-md-5 col-lg-6">
              {/* <!-- Calendly inline widget begin --> */}
              <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/chatwithintegrarealty?primary_color=b1b1b1"
        style={{ minWidth: '320px', height: '880px' }}
      />
            </div>
            
            </div>
        </section>
      </div>
          {/* <!-- Footer--> */}
          <Footer />
    </main>
     </div>
  );
}

export default Marketing;