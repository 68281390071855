
import React from 'react'; 
import Corporate from "./img/Corporate.png";
import Orem from "./img/orem.png";
import Ogden from "./img/ogden.png";
import Elite from "./img/elite.png";
import Parkcity from "./img/parkcity.png";
import George from "./img/george.png";


import Header from "./header";
import Footer from "./footer";

function Location() {
    return (
      <div>
    <main className="page-wrapper">
    <Header />

      {/* <!-- How it works--> */}
      <div className="common_page_layout">
      <p class="mb-2 pb-2 mx-auto text-dark text-center"><span class="left-horizontal-bar position-relative ps-5">LOCATIONS</span></p>
      <h2 class="text-center">OUR OFFICES</h2>      
      <section class="container pt-5 pb-lg-4">
            <div class="row gy-4 align-items-center">
          <div class="col-md-6">
            <h2 class="h3">In Depth Realty Corporate</h2>
            <p className="mb-0 pb-md-3 text-dark opacity-70">
            10808 South River Front Parkway, Suite 3065<br></br> South Jordan, Utah 84095 </p>
            <p className="mb-0 pb-md-3 text-dark opacity-70"><b>Phone :</b> 801-576-2564</p>
            <p className="mb-0 pb-md-3 text-dark opacity-70"><b>Hours :</b> Monday-Friday 8:30 AM-5:00 PM</p>
          </div>
          <div class="col-md-6">
                <img class="d-block mx-auto img-round" src={Corporate} alt="Illustration" />
            {/* <img class="d-block mx-auto mt-3" src="https://movetointegra.com/wp-content/uploads/4-2-e1677597776677.jpg" alt="Illustration" /> */}

          </div>
        </div>
      </section>
      <section class="container pt-5 pb-lg-4">
              <div class="row gy-4 align-items-center">
                <div class="col-md-6 order-md-1 order-sm-2 order-2">
                  <img class="d-block mx-auto img-round" src={Orem} alt="Illustration" />
              </div>
                <div class="col-md-6 order-md-2 order-sm-1 order-1">
                  <h2 class="h3">In Depth Realty Orem</h2>
                  <p className="mb-0 pb-md-3 text-dark opacity-70">
                    504 West 800 North Orem,<br></br> UT 84057</p>
                  <p className="mb-0 pb-md-3 text-dark opacity-70"><b>Phone :</b> 801-576-2564</p>
                  <p className="mb-0 pb-md-3 text-dark opacity-70"><b>Hours :</b> Monday-Friday 8:30 AM-5:00 PM</p>
            </div>
      
        </div>
      </section>
      <section class="container pt-5 pb-lg-4">
        <div class="row gy-4 align-items-center">
          <div class="col-md-6">
            <h2 class="h3">In Depth Realty Ogden</h2>
            <p className="mb-0 pb-md-3 text-dark opacity-70">
                    221 25th StreetOgden,<br></br> UT 84401 </p>
            <p className="mb-0 pb-md-3 text-dark opacity-70"><b>Phone :</b> 801-576-2564</p>
            <p className="mb-0 pb-md-3 text-dark opacity-70"><b>Hours :</b> Monday-Friday 8:30 AM-5:00 PM</p>
          </div>
          <div class="col-md-6">
            <img class="d-block mx-auto img-round" src={Ogden} alt="Illustration" />
          </div>
        </div>
      </section>
      <section class="container pt-5 pb-lg-4">
        <div class="row gy-4 align-items-center">
          <div class="col-md-6 order-md-1 order-sm-2 order-2">
            <img class="d-block mx-auto img-round" src={Elite} alt="Illustration" />
          </div>
          <div class="col-md-6 order-md-2 order-sm-1 order-1">
            <h2 class="h3">In Depth Realty Elite</h2>
            <p className="mb-0 pb-md-3 text-dark opacity-70">
                    10808 South River Front Parkway<br></br>
              Suite 3065South Jordan,
              Utah, UT 84095</p>
            <p className="mb-0 pb-md-3 text-dark opacity-70"><b>Phone :</b> 801-576-2564</p>
            <p className="mb-0 pb-md-3 text-dark opacity-70"><b>Hours :</b> Monday-Friday 8:30 AM-5:00 PM</p>
          </div>
        </div>
      </section>
      <section class="container pt-5 pb-lg-4">
        <div class="row gy-4 align-items-center">
          <div class="col-md-6">
            <h2 class="h3">In Depth Realty Park City</h2>
            <p className="mb-0 pb-md-3 text-dark opacity-70">
                    1389 Center Drive, Suite<br></br>
              200Park City, UT 84098</p>
            <p className="mb-0 pb-md-3 text-dark opacity-70"><b>Phone :</b> 801-576-2564</p>
            <p className="mb-0 pb-md-3 text-dark opacity-70"><b>Hours :</b> Monday-Friday 8:30 AM-5:00 PM</p>
          </div>
          <div class="col-md-6">
            <img class="d-block mx-auto img-round" src={Parkcity} alt="Illustration" />
          </div>
        </div>
      </section>
      <section class="container pt-5 pb-lg-4 mb-5">
        <div class="row gy-4 align-items-center">
          <div class="col-md-6 order-md-1 order-sm-2 order-2">
            <img class="d-block mx-auto img-round" src={George} alt="Illustration" />
          </div>
          <div class="col-md-6 order-md-2 order-sm-1 order-1">
            <h2 class="h3">In Depth Realty St. George</h2>
            <p className="mb-0 pb-md-3 text-dark opacity-70">
                    169 West 2710 South Circle Suite 203A<br></br>
              Saint Goerge, UT 84020</p>
            <p className="mb-0 pb-md-3 text-dark opacity-70"><b>Phone :</b> 801-576-2564</p>
            <p className="mb-0 pb-md-3 text-dark opacity-70"><b>Hours :</b> Monday-Friday 8:30 AM-5:00 PM</p>
          </div>
        </div>
      </section>
      </div>
          {/* <!-- Footer--> */}
          <Footer />
    </main>
     </div>
  );
}

export default Location;