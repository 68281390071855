import React from "react";
import { NavLink } from 'react-router-dom';
import photo1 from "./img/light-logo.png";
function Footer (){
    return(
    <>
            <footer class="footer pt-lg-5 bg-black text-light">
                <div class="container py-4 pb-lg-5 px-lg-0 px-lg-0 px-sm-4 px-4">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="mb-4"><a class="d-inline-block" href=""><img src={photo1} width="116" alt="Logo" /></a></div>
                            <p class="fs-sm mb-4">Experience, Innovation, Support</p>
                            {/* <!-- Socials--> */}
                            <div class="text-nowrap mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                                <a class="btn btn-icon btn-translucent-light btn-xs rounded-circle" href="#"><i class="fi-facebook"></i></a>
                                <a class="btn btn-icon btn-translucent-light btn-xs rounded-circle ms-3" href="#"><i class="fi-twitter"></i></a>
                                <a class="btn btn-icon btn-translucent-light btn-xs rounded-circle ms-3" href="#"><i class="fi-telegram"></i></a>
                                <a class="btn btn-icon btn-translucent-light btn-xs rounded-circle ms-3" href="#"><i class="fi-messenger"></i></a>
                            </div>

                            {/* <ul class="nav nav-light flex-column">
                                <li class="nav-item mb-2"><a class="nav-link p-0 fw-normal text-light text-nowrap" href="mailto:hello@movetoindepthrealty.com"><i class="fi-mail mt-n1 me-1 align-middle text-primary"></i>hello@movetoindepthrealty.com</a></li>
                                <li class="nav-item mb-2"><a class="nav-link p-0 fw-normal text-light text-nowrap" href="tel:4065550120"><i class="fi-device-mobile mt-n1 me-1 align-middle text-primary"></i>(801) 381-3352</a></li>
                            </ul> */}
                        </div>
                        {/* <!-- Links--> */}
                        <div class="col-lg-2 col-md-3 col-sm-6 col-6">
                            <h3 class="fs-base text-light">Resources</h3>
                            <ul class="list-unstyled fs-sm">
                                <li><a class="nav-link-light text-light float-start w-100 mb-3" href="#">Top cities</a></li>
                                <li><a class="nav-link-light text-light float-start w-100 mb-3" href="#">Accommodation</a></li>
                                <li><a class="nav-link-light text-light float-start w-100 mb-3" href="#">Cafes &amp; restaurants</a></li>
                                <li><a class="nav-link-light text-light float-start w-100 mb-3" href="#">Events</a></li>
                            </ul>
                        </div>
                        {/* <!-- Links--> */}
                        <div class="col-lg-2 col-md-3 col-sm-6 col-6">
                            <h3 class="fs-base text-light">Profile</h3>
                            <ul class="list-unstyled fs-sm">
                                <li><a class="nav-link-light text-light float-start w-100 mb-3" href="#">My account</a></li>
                                <li><a class="nav-link-light text-light float-start w-100 mb-3" href="#">Favorites</a></li>
                                <li><a class="nav-link-light text-light float-start w-100 mb-3" href="#">My listings</a></li>
                                <li><a class="nav-link-light text-light float-start w-100 mb-3" href="#">Add listings</a></li>
                            </ul>
                        </div>
                        {/* <!-- Subscription form--> */}
                        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-1">
                            <h3 class="h4 text-light">Subscribe to our newsletter</h3>
                            <p class="fs-sm mb-4">Don’t miss any relevant vacancies!</p>
                            <form class="form-group form-group-light" >
                                <div class="input-group input-group-sm"><span class="input-group-text text-muted"><i class="fi-mail"></i></span>
                                    <input class="form-control" type="email" placeholder="Your email" />
                                </div>
                                <button class="btn btn-secondary btn-sm" type="button">Subscribe</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="py-4 border-top border-light">
                    <div class="container d-flex flex-column flex-lg-row align-items-center justify-content-between py-2 px-0">
                        {/* <!-- Copyright--> */}
                        <div class="d-flex flex-lg-row flex-column align-items-center mb-lg-0 mb-4">
                            {/* <!-- Links--> */}
                            <div class="d-flex flex-wrap fs-sm mb-lg-0 mb-4">
                                <NavLink className="text-white text-decoration-none me-3 border-0" to="/about">About</NavLink>
                                <NavLink className="text-white text-decoration-none me-3">Blog</NavLink>
                                <NavLink className="text-white text-decoration-none me-3">Support</NavLink>
                                <NavLink className="text-white text-decoration-none me-3" to="/location">Locations</NavLink>
                                <NavLink className="text-white text-decoration-none">Contacts</NavLink>
                            </div>
                            {/* <div class="d-flex align-items-center">
                                <div class="dropdown"><a class="nav-link nav-link-light dropdown-toggle fs-sm align-items-center p-0 fw-normal" href="#" id="langSwitcher" data-bs-toggle="dropdown" role="button" aria-expanded="false"><i class="fi-globe mt-n1 me-2 align-middle"></i>Eng</a>
                                    <ul class="dropdown-menu dropdown-menu-dark my-1" aria-labelledby="langSwitcher">
                                        <li><a class="dropdown-item text-nowrap py-1" href="#"><img class="me-2" src="img/flags/de.png" width="20" alt="Deutsch" />Deutsch</a></li>
                                        <li><a class="dropdown-item text-nowrap py-1" href="#"><img class="me-2" src="img/flags/fr.png" width="20" alt="Français" />Français</a></li>
                                        <li><a class="dropdown-item text-nowrap py-1" href="#"><img class="me-2" src="img/flags/es.png" width="20" alt="Español" />Español</a></li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                        <p class="order-lg-1 order-2 fs-sm mb-2 mb-lg-0 order-lg-2 order-1"><span class="text-light">&copy; All rights reserved.</span><a class="nav-link-light fw-bold" href="" target="_blank" rel="noopener">IndepthRealty</a></p>
                    </div>
                </div>
            </footer>
    </>
    
    )
}
export default Footer;