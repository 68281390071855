import logo from './logo.svg';
import './App.css';
import Home from './component/home.js';
import About from './component/about.js';
import Story from './component/story.js';
import ContactUs from './component/contact.js';
import Corporate from './component/integra-realty-corporate.js';
import OgdenOffice from './component/our-locations-ogden-office.js';
import OremOffice from './component/our-locations-orem-office.js';
import GeorgeOffice from './component/our-locations-saint-george-office.js';
import JordanOffice from './component/our-locations-south-jordan-office.js';
import ParkCity from './component/park-city-office.js';
import FAQ from './component/faq.js';
import Broker from './component/broker.js';
import Commission from './component/commission.js';
import Referral from './component/referral.js';
import Training from './component/training.js';
import Technology from './component/technology.js';
import Marketing from './component/marketing.js';
import Appointment from './component/appointment.js';
import AgentsListing from './component/agents-listing.js';
import Contact from './component/contact.js';
import Location from './component/location.js';


import Footer from './component/footer.js';
import Header from './component/header.js';
import { Routes, Route } from 'react-router-dom';


function App() {
  return (
    <div>
      {/* <Header /> */}
      <Routes>
      <Route path="/story" element={<Story />}/>
      <Route path="/about" element={<About />}/>
      <Route index element={<Home />}/>
      <Route path="/home" element={<Home />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/indepth-realty-corporate" element={<Corporate />} />
      <Route path="/our-locations-ogden-office" element={<OgdenOffice />} />
      <Route path="/our-locations-orem-office" element={<OremOffice />} />
      <Route path="/our-locations-saint-george-office" element={<GeorgeOffice />} />
      <Route path="/our-locations-south-jordan-office" element={<JordanOffice />} />
      <Route path="/park-city-office" element={<ParkCity />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/broker" element={<Broker />} />
      <Route path="/commission" element={<Commission />} />
      <Route path="/referral" element={<Referral />} />
      <Route path="/training" element={<Training />} />
      <Route path="/technology" element={<Technology />} />
      <Route path="/marketing" element={<Marketing />} />
      <Route path="/location" element={<Location />} />
      <Route path="/appointment" element={<Appointment />} />
      <Route path="/agents-listing" element={<AgentsListing />} />
      <Route path="/footer" element={<Footer/>} />
       {/* <Route path="/" element={<ProtectedRoute Component={<Home />} />} /> */}
      </Routes>
      </div>
  );
}

export default App;
