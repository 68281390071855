import React, { useEffect } from 'react';
import photo1 from "./img/logo.png";
import photo2 from "./img/swirly-arrow.png";
import photo3 from "./img/turn-right-arrow.png";
import equalpay from "./img/equal-pay.png";
import zerofees from "./img/zero-fees.png";
import marketing from "./img/marketing.png";
import commission from "./img/commission.png";
import support from "./img/support.png";
import technology from "./img/technology.png";
import training from "./img/training.png";
import office from "./img/office-space.png";
import homepage from "./img/homepage.jpg";
import { NavLink } from 'react-router-dom';
import Header from "./header";
import Footer from "./footer";


function OremOffice() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <body class="">
    <div>
   <main className="page-wrapper">
          <Header />

     
          <section class="container common_page_layout pb-lg-5 mb-0">
            <div class="row gy-4">
              <div class="col-lg-4 col-md-6">
                <div class="mb-md-5 mb-4 pb-md-4">
                  <h3 class="mb-md-3 text-dark">OUR LOCATIONS</h3>
                  <p class="mb-0 fs-lg text-dark opacity-70">Orem Office UTAH</p>
                </div>
                <div class="d-flex align-items-start mb-4 pb-md-3"><i class="h2 fi-device-mobile opacity-80 me-3"></i>
                  <div>
                    <h3 class="h6 mb-2 pb-1 text-dark">Phone</h3>
                    <p class="mb-0 text-dark"><span class="opacity-70">801-576-2564</span></p>
                  </div>
                </div>
                {/* <div class="d-flex align-items-start mb-4 pb-md-3"><img class="me-3 flex-shrink-0" src="https://finder.createx.studio/img/car-finder/icons/chat.svg" width="32" alt="Chat icon"/>
                  <div>
                    <h3 class="h6 mb-2 pb-1 text-dark">General communication</h3>
                    <p class="mb-0 text-dark"><span class="opacity-70">We’re here to help! If you have technical issues</span><a class="ms-1 text-nowrap" href="car-finder-help-center.html">contact support</a></p>
                  </div>
                </div> */}
                <div class="d-flex align-items-start mb-4 pb-md-3"><i class="h2 fi-map-pin opacity-80 me-3"></i>
                  <div>
                    <h3 class="h6 mb-2 pb-1 text-dark">Our headquarters</h3>
                    <p class="mb-0 text-dark"><span class="opacity-70">504 West 800 North Orem, UT 84057</span></p>
                  </div>
                </div>
                <div class="d-flex align-items-start mb-4 pb-md-3"><i class="h2 fi-alarm opacity-80 me-3"></i>
                  <div>
                    <h3 class="h6 mb-2 pb-1 text-dark">Hours</h3>
                    <p class="mb-0 text-dark"><span class="opacity-70">Monday- Friday 8:30 AM-5 PM</span></p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 offset-lg-2 d-flex align-items-center justify-content-center">
                <img src="https://movetointegra.com/wp-content/uploads/Orem-7.jpg" class="img-round" alt=""/>
              </div>
            </div>
          </section>
          
          
          <section className="container-fluid pb-5 p-3 m-0 orange-bg">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <p className="mb-2 pb-2 mx-auto text-dark"><span className="left-horizontal-bar position-relative ps-5">JOIN In Depth Realty</span></p>
              <h2 className="mb-2">Schedule an Appointment​</h2>
              <p className="mb-lg-4 fs-lg text-muted">Come learn about why In Depth Realty is one of the most innovative brokerages in Utah! Please select one of the appointment options below. We’re available whether you’d like to meet in person, online, or chat on the phone</p>
              <h5 className="mb-2 text-center">SCHEDULE NOW!</h5>
            </div>
            <div class="col-md-5 col-lg-6">
              {/* <!-- Calendly inline widget begin --> */}
              <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/chatwithintegrarealty?primary_color=b1b1b1"
        style={{ minWidth: '320px', height: '880px' }}
      />
            </div>
            
            </div>
        </section>
        <section class="elementor-custom-embed  mt-lg-0 mt-md-0 mt-sm-5 mt-5">
            <iframe loading="lazy" src="https://maps.google.com/maps?q=504%20West%20800%20North%2C%20Orem%20Utah%2084057%E2%80%8B&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="504 West 800 North, Orem Utah 84057&ZeroWidthSpace;" aria-label="504 West 800 North, Orem Utah 84057&ZeroWidthSpace;"></iframe></section>
      {/* <!-- Footer--> */}
          {/* <!-- Footer--> */}
          <Footer />
    </main>
    </div>
    </body>
        
  );
}

export default OremOffice;
