
import React, { useEffect } from 'react';
import indepthmackbook from "./img/Indepth-Macbook.png";
import agenttools from "./img/Agent-Personal-Tools.png";
import indepthscreenshot from "./img/Indepth-realty-screenshot.png";
import Movers from "./img/Movers.jpg";
import Support from "./img/Moving-services.png";
import Header from "./header";
import Footer from "./footer";



import { NavLink } from 'react-router-dom';

function Technology() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div>

      <main className="page-wrapper">
        <Header />

        <section class="container common_page_layout pb-5  mb-md-3">
          <div class="row align-items-center">
            <div class="col-md-12  text-center ">
              <h2 class="mb-md-4">Technology</h2>
            </div>
          </div>
        </section>

        <section class="container mb-5">
          <div class="row gy-4 align-items-center">
            <div class="col-md-6">
              <h5 class="">Work Anytime And Anyplace With Our Agent Transaction And Marketing System!</h5>
              <p class="mt-3">Our Agent ofﬁce system has been designed to be an all in one broker/agent paperless transaction platform. lt encompasses key features our agents need to fully operate in the real estate industry, into one easy to use, comprehensive solution. Features such as a transaction manager, document repository, contact database, training resources and tools for creating and sending marketing materials can now be found all in one site allowing our agents to do business any time and any place.</p>
              <p class="mt-3">Each of our agents can create a personal website showcasing homes with their clients. Our brokers and agents can use the system to push information to other agents and to their branch broker and the corporate ofﬁce, reinforce partnerships with vendors and build onto the ever expanding community knowledge base training. Agents will enjoy the ease of communication, and sharing of information and paperwork, all seamlessly online. Our predictive workﬂows let our agents know what documents or actions are expected as they progress throughout a transaction eliminating the number one complaint — lack of communication!</p>
            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-center">
              {/* <img class="d-block mx-auto" src={indepthmackbook} alt="Illustration" /> */}
              <img fetchpriority="high" decoding="async" class="img-round" src={agenttools} alt="" sizes=""></img>
            </div>
          </div>
        </section>
  

        {/* <!-- How it works--> */}
        <section class="container mb-5 pt-5 pb-5 mt-4 pb-lg-4">
          <div class="row gy-4 align-items-center">
            <div class="col-md-6 order-md-1 order-sm-2 order-2">
              <img decoding="async" class="img-round" src={indepthscreenshot} alt=""></img>
            </div>
            <div class="col-md-6 order-md-2 order-sm-1 order-1">
              <h2 class="h3">Transaction Workflows</h2>
              <p class="mt-3">Going well beyond traditional static transaction management, our predictive workﬂows lets our agents, brokers and clients know what documents or actions are expected as they progress throughout a transaction.</p>
              <p class="mt-3">Our dynamic transaction interview helps ensure that the agent follows the right path, and gathers the correct documentation for every transaction, every time. This eliminates the back and forth that coordinators or managers go through with agents in the typical brokerage. You can always see at a glance what is needed or missing 24/7.</p>
            </div>
          </div>
        </section>

        <section class="container mb-5 pt-5 pb-5 mt-4 pb-lg-4">
          <div class="row gy-4 align-items-center">
            <div class="col-md-6">
              <h2 class="h3">Our Online Offer Management System</h2>
              <p class="mt-3">Our offer submission link tool allows are agents and sellers to streamline the offer process by placing a simple unique offer link for each listing into your MLS and direct all offers to be submitted through that web page. Our agent and sellers both receive an email when an offer has been made on their property and allows them to login to their account and view the offer and net sheet allowing them to manage the offer process online.</p>
              <p class="mt-3">This streamlines the entire offer process and adds a lot of transparency for all involved also giving our agents an archived offer database for each property and any associated communications for that offer.
                By accepting offers online all of your offers are able to managed, searched, sorted, and saved in one central location. This eliminates fax, email clutter</p>

            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-center">
              <img decoding="async" class="img-round" src={Movers} alt=""></img>
            </div>
          </div>
        </section>

        <section class="container mb-5 pt-5 pb-5 mt-4 pb-lg-4">
          <div class="row gy-4 align-items-center">
            <div class="col-md-6 order-md-1 order-sm-2 order-2">
              <img decoding="async" class="img-round" src={Support} alt=""></img>
            </div>
            <div class="col-md-6 order-md-2 order-sm-1 order-1">
              <h2 class="h3">Free Moving Services for your Clients</h2>
              <p class="mt-3">Clients hate moving — So we want to make it easier for them. In Depth Realty provides our agents with a solution to help their clients organize and complete move-related tasks before closing and moving.</p>
              <p class="mt-3">You don’t even have to do anything extra. just keep your contracts and dates updated within our website and let our technology do the rest. its kind of like Turbo Tax for moving. Our system will send your client and branded email like this one:</p>
            </div>
          </div>
        </section>

        <section className="container-fluid pb-5 p-3 m-0 orange-bg">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <p className="mb-2 pb-2 mx-auto text-dark"><span className="left-horizontal-bar position-relative ps-5">JOIN In Depth Realty</span></p>
              <h2 className="mb-2">Schedule an Appointment​</h2>
              <p className="mb-lg-4 fs-lg text-muted">Come learn about why In Depth Realty is one of the most innovative brokerages in Utah! Please select one of the appointment options below. We’re available whether you’d like to meet in person, online, or chat on the phone</p>
              <h5 className="mb-2 text-center">SCHEDULE NOW!</h5>
            </div>
            <div class="col-md-5 col-lg-6">
              {/* <!-- Calendly inline widget begin --> */}
              <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/chatwithintegrarealty?primary_color=b1b1b1"
        style={{ minWidth: '320px', height: '880px' }}
      />
            </div>
            
            </div>
        </section>
        {/* <!-- Footer--> */}
        <Footer />
      </main>
    </div>
  );
}

export default Technology;