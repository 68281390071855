
import React, { useEffect } from 'react';
import photo1 from "./img/logo.png";
import carousel1 from "./img/01.jpg";
import iphone from "./img/iphone.png";
import brokerimage from "./img/broker-image.png";
import indepthmackbook from "./img/Indepth-Macbook.png";
import Header from "./header";
import Footer from "./footer";

import { NavLink } from 'react-router-dom';

function Referral() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
    return (
      <div>
     
    <main className="page-wrapper">
          <Header/>
          <section class="common_page_layout container mb-5 pb-lg-5">
            <div class="row align-items-center justify-content-lg-start justify-content-center flex-lg-nowrap gy-4">
              <div class="col-lg-8 order-md-1 order-sm-2 order-2"><img class="rounded-3" src="https://movetointegra.com/wp-content/uploads/referral-landing-23.jpg" alt="Hero image"/></div>
              <div class="col-lg-5 ms-lg-n5 col-sm-9 text-lg-start order-md-2 order-sm-1 order-1">
                <div class="ms-lg-n5 card border-0 shadow-sm ms-sm-n5 p-3">
                  <h1 class="mb-lg-4 text-dark">Keep An Active</h1>
                  <h6>Real Estate License</h6>
                  <p class="mb-4 pb-lg-3 fs-lg text-dark opacity-70">Without the dues and fees. Need options for your Utah Real Estate License? Whether you’re retiring, taking a break, too busy, or any other reason. Become a referral agent and easily go back to fully active anytime.</p>
                </div>
              </div>
            </div>
          </section>
          <section class="container mb-5 pb-lg-5 pb-2 pb-sm-3">
            <div class="row gy-4 align-items-lg-center">
              <div class="col-lg-6 col-md-6 text-md-start">
                <h4>Why Join</h4>
                <h2 class="mb-md-4 text-dark">In Depth Realty Referral?</h2>
                <p class="mb-0 pb-md-3 text-dark opacity-70">Never any Realtor, MLS or E&O Fees</p>
                <p class="mb-0 pb-md-3 text-dark opacity-70">Keep Your Real Estate License Active</p>
                <p class="mb-0 pb-md-3 text-dark opacity-70">Keep Earning Commissions</p>
                <p class="mb-0 pb-md-3 text-dark opacity-70">Earn Commission on Your Personal Transactions</p>
              </div>
              <div class="col-md-6"><img class="rounded-3" src="https://movetointegra.com/wp-content/uploads/referral-notepad.jpg" alt="Personalized search" /></div>
            </div>
          </section>
          <section class="container mb-5 pb-lg-5 pb-2 pb-sm-3">
            <div class="row gy-4 align-items-lg-center">
              <div class="order-md-2 order-sm-1 order-1 col-lg-5 offset-lg-1 col-md-6">
                <h4>Who is</h4>
                <h2 class="mb-md-4 text-dark">In Depth Realty Referral for?</h2>
                <p class="mb-0 pb-md-3 text-dark opacity-70">Retiring or recently retired Agents and brokers</p>
                <p class="mb-0 pb-md-3 text-dark opacity-70">Part time real estate agents and brokers who find that paying MLS and board fees is not cost effective</p>
                <p class="mb-0 pb-md-3 text-dark opacity-70">Agents who are changing careers</p>
                <p class="mb-0 pb-md-3 text-dark opacity-70">Real estate agents that have decided that sales are not
                  for them</p>
                <p class="mb-0 pb-md-3 text-dark opacity-70">Real estate agents that buy and sell as principals</p>
                <p class="mb-0 pb-md-3 text-dark opacity-70">Real estate agents that want to maintain a tax
                  deductible status</p>
                <p class="mb-0 pb-md-3 text-dark opacity-70">Agents moving to another state</p>
                <p class="mb-0 pb-md-3 text-dark opacity-70">Agents taking a temporary break
                </p>
                <p class="mb-0 pb-md-3 text-dark opacity-70">New Agents not yet ready to engage in active sales
                </p>
                <p class="mb-0 pb-md-3 text-dark opacity-70">Any Agent who doesn’t want to throw away their
                  Valuable licenses</p>
                <p class="mb-0 pb-md-3 text-dark opacity-70">Real estate agents who are employed in another field, but want to use their license to earn commission such as probate attorneys, bankruptcy attorneys, family law attorneys, financial planners, and CPA, s.</p>

              </div>
              <div class="col-md-6 text-md-start order-md-1 order-sm-2 order-2"><img class="rounded-3" src="https://movetointegra.com/wp-content/uploads/referral-mega.jpg" alt="Attractive selling conditions" /></div>
              
            </div>
          </section>
          <section class="container mb-5 pb-lg-5 pb-3 pb-sm-4">
            <div class="row gy-4 align-items-lg-center">
              <div class="col-lg-12 col-md-12">
              <h2 className="text-center">Do’s and Don'ts of a referral agent</h2> 
              </div>            
              <div class="col-lg-6 col-md-6 text-md-start">
                <h5 class="mb-4 pb-2 text-dark text-center">Do:</h5>
                <div class="mx-auto">
                  <div class="steps steps-light steps-vertical">
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">1</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Market to your sphere of influence to tell them you can help them with their real estate needs</p>
                      </div>
                    </div>
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">2</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Prospect for new buyers and sellers</p>
                      </div>
                    </div>
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">3</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Obtain the prospect's contact information</p>
                      </div>
                    </div>
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">4</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Any other marketing items must be approved by sending an email to the broker.</p>
                      </div>
                    </div>
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">5</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Keep your license active at the Division of Real Estate and notify them promptly of any changes to contact info, or any other required notifications</p>
                      </div>
                    </div>
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">6</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Keep up with your continuing education classes so you can renew your license</p>
                      </div>
                    </div>
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">7</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Make referrals in all 50 states</p>
                      </div>
                    </div>
                  </div>

                </div>
              
             </div>
              <div class="col-lg-6 col-md-6 text-md-start">
                <h5 class="mb-4 pb-2 text-dark text-center">Don’t:</h5>
                <div class="mx-auto">
                  <div class="steps steps-light steps-vertical">
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">1</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Represent yourself as a REALTOR®, however you can identify yourself as a real estate agent</p>
                      </div>
                    </div>
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">2</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Access mls or obtain association forms through another agent - this is against many rules!</p>
                      </div>
                    </div>
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">3</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Use another agents lockbox key</p>
                      </div>
                    </div>
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">4</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Hold open houses for other agents
</p>
                      </div>
                    </div>
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">5</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Make listing or sales presentations
</p>
                      </div>
                    </div>
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">6</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Advise the public on real estate transactions
</p>
                      </div>
                    </div>
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">7</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Represent yourself as anything other than a referral agent in any real estate capacity
</p>
                      </div>
                    </div>
                    {/* <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">8</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Provide comparables or sales data - without mls access you can not provide pricing assistance
</p>
                      </div>
                    </div>
                    <div class="step">
                      <div class="step-progress"><span class="step-progress-end"></span><span class="step-number bg-primary shadow-hover">9</span></div>
                      <div class="step-label text-dark">
                        <p class="mb-0">Stress about association and mls fees! You don’t have to pay them anymore
</p>
                      </div>
                    </div> */}
                  </div>

                </div>
              </div>
            </div>
          </section>
          <div className="float-left w-100 become_referal_agent mb-5">
          <section class="container">
              <div className="d-flex align-items-center justify-content-between">
                <img className="img-fluid me-lg-5 me-md-5 me-sm-2 me-0" src="https://movetointegra.com/wp-content/uploads/referral-mineta.jpg"></img>
                <div className="ms-lg-5 ms-md-5 ms-sm-2 ms-0">
                  <h2 className="mb-4 pb-2 text-dark text-left">BECOME A REFERRAL AGENT</h2>
                  <div className="text-dark">
                    <p className="mb-2 pb-md-3 text-dark opacity-70">Becoming a Referral Agent with In Depth Realty Referral allows you the opportunity to keep your Utah real estate license active and continue earning thousands of dollars for your referrals. There are never any Realtor, MLS, or E&O fees! You will end up saving hundreds, if not thousands, on fees every year. Joining In Depth Realty Referral is a super easy.
                      Don’t lose your Utah real estate license or let it go inactive, join In Depth Realty Referral today.</p>
                    <p className="mb-2 pb-md-3 text-dark opacity-70">To park your license there’s a low membership fee: $50 per year. You reap all the rewards of your referrals less our low & simple fee of $495.00 per transaction fee for commission checks $5,000 and under, or a flat $695 for commission checks $5,001 and over.</p>
                    <p className="mb-2 pb-md-3 text-dark opacity-70">We’re a completely independent brokerage, so you may place your referral with the agent of your choice or allow us to select the perfect fit. You can refer residential, commercial, new construction, or land deals, in any state. Make easy money with your valuable Utah real estate license.</p>
                    <p className="mb-2 pb-md-3 text-dark opacity-70">Ready to join? Move your license in minutes! check out our FAQ for answers to the most commonly asked questions about In Depth Realty Referral.</p>
                    <div className="float-left w-100 mt-4">
                      <a class="btn btn-primary btn-md rounded-pill order-md-3" href="#" target="_blank" rel="noopener">join now </a>
                    </div>
                  </div>
              </div>
            </div>
          </section>
          </div>
          <section className="container d-flex align-items-center justify-content-center mb-5 pb-lg-5 pb-3 pb-sm-4 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
            <div className="col-md-6">
            <h1 className="mb-lg-4 text-dark text-center">FAQ</h1>
              <div className="pb-md-2">
                <h3 className="h5">If I decide to become actively involved in real estate sales, can I transfer to full service status with In Depth Realty?</h3>
                <p>Absolutely. We have offices all over Utah.</p>
            </div>
              <div className="pb-md-2">
                <h3 className="h5">Do I have to pay Realtor, MLS, or E&O fees?</h3>
                <p>Absolutely Not! You will never have to pay Realtor fees, MLS fees, or E&O fees while your license is parked at In Depth Realty Referral. Easily save hundreds, if not thousands, per year on those pesky fees. You won’t be a member of any board of Realtors or MLS service. As a virtual office, we do not have mandatory meetings or other obligations. Simply park your license and refer any real estate business to keep a steady income stream.
                  Our incredibly low membership fee is just $50 per year. Parking your real estate license is an affordable alternative to paying expensive Realtor, MLS and E&O fees.</p>
              </div>
              <div className="pb-md-2">
                <h3 className="h5">How do I get paid?</h3>
                <p>Once In Depth Realty receives a commission check from your referral closing agent, we will wire your funds as soon as the incoming funds clear our bank account, typically within 1-3 business days. As an independent contractor you will receive a 1099 to report your earnings at the end of the year. We do not deduct taxes.</p>
              </div>
            </div>
         </section>

         <section className="container-fluid p-3 pb-5 m-0 orange-bg">
          <div className="row align-items-lg-center g-5">
            <div className="col-lg-6 col-md-6">
              <p className="mb-2 pb-2 mx-auto text-dark"><span className="left-horizontal-bar position-relative ps-5">JOIN In Depth Realty</span></p>
              <h2 className="mb-2">Schedule an Appointment​</h2>
              <p className="mb-lg-4 fs-lg text-muted">Come learn about why In Depth Realty is one of the most innovative brokerages in Utah! Please select one of the appointment options below. We’re available whether you’d like to meet in person, online, or chat on the phone</p>
              <h5 className="mb-2 text-center">SCHEDULE NOW!</h5>
            </div>
            <div class="col-md-5 col-lg-6">
              {/* <!-- Calendly inline widget begin --> */}
              <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/chatwithintegrarealty?primary_color=b1b1b1"
        style={{ minWidth: '320px', height: '880px' }}
      />
            </div>
            
            </div>
        </section>
          {/* <!-- Footer--> */}
          <Footer />
    </main>
     </div>
  );
}

export default Referral;